import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import React, { useEffect, useRef, useState } from "react";
import "./SelectedSubjects.css";
import Modal from "../Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { selectInscribedSubjects, selectLoadingRequestInTransit } from "../../reduxSlices/subjectsSlice";
import { selectEditionMode } from "../../reduxSlices/editorModeSlice";
import useUnsubscribeSection from "../../hooks/useUnsubscribeSection";
import { updateDetailSubject } from "../../reduxSlices/detailSubjectSlice";
import { IInscribedSubject, IInscribedSubjectSection } from "../../types";
import Spinner from "../Spinner/Spinner";
import { selectConfirmationsFlag } from "../../reduxSlices/parametersSlice";

interface ISelectedSubject {
  title: string;
  sectionId: number;
  subjectId: number;
  active: boolean;
}

interface IPropsSelectedSubjects {
  data: ISelectedSubject[];
}

interface ISubjectToUnsub {
  sectionId: number;
  section: string;
}

const SelectedSubjects = ({ data }: IPropsSelectedSubjects) => {
  const [open, setOpen] = useState(false);
  const loadingRequestInTransit = useSelector(selectLoadingRequestInTransit);
  const [subjectToUnsub, setSubjectToUnsub] = useState<ISubjectToUnsub>();
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const confirmationsEnabled = useSelector(selectConfirmationsFlag);

  const { on } = useSelector(selectEditionMode);

  const unsubscribeSection = useUnsubscribeSection();

  const handleSetDetailSubject = (id: number) => {
    const sections =
      inscribedSubjects?.filter((subject: IInscribedSubject) => subject.idCurso === id)[0].sections || [];
    if (sections?.length) {
      dispatch(updateDetailSubject({ sections }));
    }
  };

  const handleUnsuscribeSection = async (idCurso: number) => {
    await unsubscribeSection(idCurso);
  };

  const handleTrashSubject = async (subject: IInscribedSubject) => {
    if (confirmationsEnabled) {
      setSubjectToUnsub({
        sectionId: subject.idCurso,
        section: subject.title,
      });
    } else {
      await handleUnsuscribeSection(subject.idCurso);
    }
  };

  const renderSelectedSubjects = (subjects: IInscribedSubject[]) => {
    return subjects.map((s, index) => {
      const isLastItem = subjects.length === index + 1;
      return (
        <div
          key={s.title + index}
          className={`selected-subjects-item ${isLastItem ? "" : "selected-subjects-item-divider"}`}
        >
          <div className="selected-subjects-item-left">
            <h2 className="selected-subjects-item-left-title">{s.title}</h2>
          </div>
          <div className="selected-subjects-item-right">
            <SlIcon
              name="eye"
              className="selected-subjects-item-right-icon-edit"
              onClick={() => handleSetDetailSubject(s.idCurso)}
            ></SlIcon>
            {on && (
              <SlIcon
                name="trash3"
                className="selected-subjects-item-right-icon-delete"
                onClick={() => handleTrashSubject(s)}
              ></SlIcon>
            )}
          </div>
        </div>
      );
    });
  };

  // useEffect(() => {
  //   console.log(
  //     "%c + Component INSCRIBED SUBJECTS >> Mounted ",
  //     "color: white; background: fuchsia; color: black;"
  //   );

  //   return () => {
  //     console.log(
  //       "%c - Component INSCRIBED SUBJECTS >> Unmounted ",
  //       "color: white; background: purple; color: black;"
  //     );
  //   }
  // }, [])

  /* ----------------------- managing click outside list ---------------------- */
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <div className="selected-subjects" ref={containerRef}>
      <div className="selected-subjects-button">
        <div className="selected-subjects-button-text">
          <p>Mis materias: </p>
          {loadingRequestInTransit ? (
            <Spinner style={{ fontSize: "1.2rem" }} />
          ) : (
            <p className="selected-subjects-button-quantity">{!inscribedSubjects ? 0 : inscribedSubjects.length}</p>
          )}
        </div>
        {inscribedSubjects && inscribedSubjects?.length > 0 && (
          <SlIcon
            onClick={() => setOpen(!open)}
            name={`${open ? "chevron-up" : "chevron-down"}`}
            className="selected-subjects-button-icon"
          ></SlIcon>
        )}
      </div>
      <div>
        {open && inscribedSubjects ? (
          <div className="selected-subjects-items">{renderSelectedSubjects(inscribedSubjects)}</div>
        ) : null}
      </div>
      {subjectToUnsub && subjectToUnsub.sectionId && (
        <Modal
          open={subjectToUnsub.sectionId ? true : false}
          onClose={() => setOpen(false)}
          message={`¿Estás seguro de que deseas eliminar ${subjectToUnsub.section} de tu oferta académica?
          El cupo quedará reservado hasta que confirmes los cambios.`}
          okButton={{
            label: "Continuar",
            buttonFn: () => {
              handleUnsuscribeSection(subjectToUnsub.sectionId);
              setSubjectToUnsub(undefined);
            },
          }}
          closeButton={{
            label: "Cancelar",
            buttonFn: () => setSubjectToUnsub(undefined),
          }}
        />
      )}
    </div>
  );
};

export default SelectedSubjects;
