import "./DesktopOfferList.css";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reserveSection } from "../../Api/reservation";
import SearchInput from "../SearchInput/SearchInput";
import Tabs from "../tabs/Tabs";
import { useToastError, useToastInfo } from "../../hooks/useToast";
import { IOfferedSubject, IQuota, IReserveError, ISelectedSection } from "../../types";
import { normalizeText } from "../../utils";
import { selectStudent, setStudentInicializado } from "../../reduxSlices/studentSlice";
import {
  removeSelectedOfferedSubject,
  selectOfferedSubjects,
  selectPinnedSubjectsList,
  selectSelectedOfferedSubjects,
  selectSubjectsQuotas,
  setLoadingRequestInTransit,
} from "../../reduxSlices/subjectsSlice";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import DesktopTab from "../DesktopTab/DesktopTab";
import DesktopSubjectList from "../DesktopSubjectList/DesktopSubjectList";
import { setModalOverlay } from "../../reduxSlices/modalsOverlaysSlice";

const filterSubjects = (subjects: IOfferedSubject[], searchTerm: string) => {
  const filteredSubjects = subjects
    .map((s: IOfferedSubject) => {
      const filteredSections = s.sections.filter((section) => {
        const matchesTitle = normalizeText(section.section).includes(normalizeText(searchTerm));
        const matchesProf = section.subjectData.subjectsByType.some((subject) => {
          return subject.subjectInfo.some((info) => {
            return info.values.profesores.some((prof) => {
              return normalizeText(prof).includes(normalizeText(searchTerm));
            });
          });
        });
        return matchesTitle || matchesProf;
      });

      return {
        ...s,
        sections: filteredSections,
      };
    })
    .filter((s: IOfferedSubject) => s.sections.length > 0);

  return filteredSubjects;
};

const DesktopOfferList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const student = useSelector(selectStudent);
  const selectedSubjects = useSelector(selectSelectedOfferedSubjects);
  const offeredSubjects = useSelector(selectOfferedSubjects);
  const subjectsQuotas = useSelector(selectSubjectsQuotas);
  const pinnedSubjectsList = useSelector(selectPinnedSubjectsList);

  const [filteredSubjects, setFilteredSubjects] = useState<IOfferedSubject[]>([]);
  const [filtro, setFiltro] = useState("OBLIGATORIO");
  const [searchTerm, setSearchTerm] = useState("");
  const [subjectsInSearch, setSubjectsInSearch] = useState<IOfferedSubject[]>([]);

  const [reserveError, setReserveError] = useState<IReserveError>({});
  const showError = useToastError();

  const token = localStorage.getItem("token");
  if (!token) {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  }

  useEffect(() => {
    console.log("%c + Component MATERIAS >> Mounted ", "color: white; background: fuchsia; color: black;");

    return () => {
      console.log("%c - Component MATERIAS >> Unmounted ", "color: white; background: purple; color: black;");
    };
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                             SET OFERTA if USER                             */
  /* -------------------------------------------------------------------------- */
  const memoizedFilteredSubjects = useMemo(() => {
    if (offeredSubjects && offeredSubjects.length) {
      dispatch(setStudentInicializado(2));
      return offeredSubjects.filter((s) => s.tipoMateria === filtro);
    } else {
      dispatch(setStudentInicializado(0));
    }
    return [];
  }, [offeredSubjects, filtro]);

  useEffect(() => {
    setFilteredSubjects(memoizedFilteredSubjects);
  }, [memoizedFilteredSubjects]);

  /* -------------------------------- Busqueda -------------------------------- */
  useEffect(() => {
    if (filteredSubjects && searchTerm.trim()) {
      const newSubjects = filterSubjects(filteredSubjects, searchTerm);
      setSubjectsInSearch(newSubjects);
    }
  }, [searchTerm]);

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */
  const handleReserve = async (selectedSection: ISelectedSection) => {
    try {
      dispatch(setModalOverlay(true))
      if (selectedSection) {
        const results = await reserveSection({
          idC: selectedSection.idC,
          idS: selectedSection.idS,
          idA: selectedSection.idA,
          tim: selectedSection.tim,
          tic: selectedSection.tic,
          idPersona: student.id.toString(),
        });

        console.log(results);

        if (results.data.responseCode !== 200) {
          dispatch(removeSelectedOfferedSubject(selectedSection.idS));
          setReserveError({
            ...reserveError,
            [selectedSection.idC]: results.data.responseMessage,
          });
          showError(results.data.responseMessage, () =>
            setReserveError((prevState) => {
              const newState = prevState;
              delete newState[selectedSection.idC];
              return newState;
            })
          );
        } else {
          await dispatch(setLoadingRequestInTransit(true));
        }
      }
    } catch (error) {
      console.log("error :>> ", error);
    } finally {
      dispatch(setModalOverlay(false))
    }
  };

  const handleSelectTab = (tipo: string) => {
    if (tipo) {
      setFiltro(tipo);
    } else {
      setFiltro("OBLIGATORIO");
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const renderSubjects = (subjects: IOfferedSubject[]) => {
    return (
      <>
        {subjects.length === 0 && <p>No cuentas con materias disponibles.</p>}
        {subjects.map((s) => {
          const notPinnedSections = s.sections.filter(curso =>pinnedSubjectsList.indexOf(curso.id)===-1)
          return (
            <DesktopSubjectList
              key={s.id}
              onReserve={(section: ISelectedSection) => handleReserve(section)}
              selectedSubjects={selectedSubjects}
              error={reserveError}
              subjectId={s.id}
              subjects={notPinnedSections}
              quotas={subjectsQuotas ? subjectsQuotas : {}}
              title={s.title}
            />
          );
        })}
      </>
    );
  };
  const renderPinnedSubjects = (subjects: IOfferedSubject[]) => {
    return (
      <>
        {subjects.length === 0 && <p>No cuentas con materias disponibles.</p>}
        {subjects.map((s) => {
          const pinnedSections = s.sections.filter(curso =>pinnedSubjectsList.indexOf(curso.id)>-1)
          
            return (
              <DesktopSubjectList
                key={s.id}
                onReserve={(section: ISelectedSection) => handleReserve(section)}
                selectedSubjects={selectedSubjects}
                error={reserveError}
                subjectId={s.id}
                subjects={pinnedSections}
                quotas={subjectsQuotas ? subjectsQuotas : {}}
                title={s.title}
              />
            );
        })}
      </>
    );
  };

  const countPinnedByType = (subjects: IOfferedSubject[])=>{
    let count = 0
    subjects.forEach((s) => {
      s.sections.forEach(curso =>{
        if(pinnedSubjectsList.indexOf(curso.id)>-1){
          count ++
        }
      })
    })
    return count
  }

  return (
    <>
      {filteredSubjects.length === 0 && student.id && offeredSubjects === null ? (
        <Loader text="Cargando" />
      ) : (
        <>
          <div className="desktop-filters-nav">
            <DesktopTab filtro={filtro} handleSelectTab={handleSelectTab} />
            <SearchInput onChange={(e: string) => setSearchTerm(e)} handleClearSearch={handleClearSearch} />
          </div>
          <div className="desktop-filters-listing">
        { pinnedSubjectsList.length>0 && countPinnedByType(filteredSubjects)>0 &&
        <>
          <h2 className="desktop-title-2">Materias Fijadas {filtro === "OBLIGATORIO" ? "Obligatorias" : "Optativas"}</h2>
          {/* {JSON.stringify(pinnedSubjectsList)}
          <hr /> */}
          {renderPinnedSubjects(filteredSubjects)}
        </>
        }
          <h2 className="desktop-title-2">Materias {filtro === "OBLIGATORIO" ? "Obligatorias" : "Optativas"}</h2>
          {searchTerm ? renderSubjects(subjectsInSearch) : renderSubjects(filteredSubjects)}
          </div>
        </>
      )}
    </>
  );
};

export default DesktopOfferList;
