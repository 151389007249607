import axios from "axios";
import { IReserveRequest } from "../types";

export const reserveSection = async (reserveData: IReserveRequest) => {
  const { data, status } = await axios.post(`/reservar`, reserveData);
  return { data, status };
};

interface ICuposAlumnoResponse {
  error: boolean;
  errorMessage: string;
  idCurso: number;
  idCupo: number;
  cantidad: number;
  semaforo: number;
}

const toCupos = (data: ICuposAlumnoResponse[]) => {
  return data.reduce((acc: any, e) => {
    return {
      ...acc,
      [e.idCurso]: { qty: e.cantidad, availability: e.semaforo },
    };
  }, {});
};

export const getCuposAlumno = async (idPersona: string) => {
  const { data, status } = await axios.post(`/reservar/getCuposAlumno?r=${Math.random().toString()}`, {
    idPersona,
  });
  const newData = toCupos(data);
  const response: ICuposAlumnoResponse[] = data;
  return { data: newData, status, response };
};

export const getCuposCurso = async (idCupoCurso: number) => {
  const { data, status } = await axios.post(`/reservar/getCuposCurso?r=${Math.random().toString()}`, {
    idCupoCurso,
  });
  const newData = toCupos([data]);
  return { data: newData, status };
};
