import { useState, KeyboardEvent, useEffect } from "react";
import "./Login.css";
import logo from "../../assets/ditella-logo.png";
import SlInput from "@shoelace-style/shoelace/dist/react/input";
import { useNavigate } from "react-router-dom";
import { auth, getEstadoAlumno, login } from "../../Api/authentication";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth/authConfig";
import { useDispatch, useSelector } from "react-redux";
import { selectParameter, updateParametersData } from "../../reduxSlices/parametersSlice";
import { checkRole } from "../../Api/role";
import { selectAdmin, updateAdminData } from "../../reduxSlices/adminSlice";
import { AppState } from "../../enums/appState.enum";
import { EstadoAlumnoCarrera } from "../../enums/alumnoCarrera.enum";
import TransitionModal from "../TransitionModal/TransitionModal";
import {
  selectStudent,
  setStudentBloqueo,
  setStudentInicializado,
  updateStudentData,
} from "../../reduxSlices/studentSlice";

interface ILoginProps {
  handleToken: (tkn: string) => void;
}
const Login = ({ handleToken }: ILoginProps) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const { appState } = useSelector(selectParameter);
  const { role } = useSelector(selectAdmin);
  const student = useSelector(selectStudent);
  const [logingIn, setLogingIn] = useState(false);

  const token = localStorage.getItem("token");

  if (token) {
    dispatch(setStudentInicializado(0));
    navigate("/");
  } else {
    dispatch(setStudentInicializado(2));
  }

  /* -------------------------------------------------------------------------- */
  /*                                  FUNCTIONS                                 */
  /* -------------------------------------------------------------------------- */
  function MicrosoftLogin() {
    const loginMS = async () => {
      try {
        await instance.loginRedirect(loginRequest);
      } catch (error) {
        console.error(error);
      }
    };
    loginMS();
  }

  /* -------------------------------------------------------------------------- */
  /*                                MONTADO Login                               */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    console.log(student);
    const token = localStorage.getItem("token");

    if (token) {
      console.log(token);
    }

    if (student.id && student.studentInitState) {
      // navigate("/");
      console.log("Go to page HOME!!!!!");
    }
  }, [student]);

  useEffect(() => {
    const actualUser = accounts[0]?.username;
    if (actualUser) {
      checkRole(actualUser).then((res) => {
        dispatch(updateAdminData({ name: actualUser, role: res }));
        // IF admin, show headermode
        dispatch(updateParametersData({ appState: AppState.BY_PASS, started: true }));
      });
    }
  }, [accounts]);

  useEffect(() => {
    if (appState === "2") {
      // console.log("Make login to get token");
      // call backend to check user admin
      // if user is admin, show form to impersonate user
      // turn on Bypass header
      // form handles the login with that user
    }
  }, [email]);

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */

  const handleKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleSubmit = () => {
    setLogingIn(true);
    handleLoginGetToken();
  };

  const handleLoginGetToken = async () => {
    try {
      const res = await login(email);
      const { token, inicializando, bloqueado, mensajeBloqueado } = res;
      console.log(res);

      if (token) {
        localStorage.setItem("token", token);
        handleToken(token);
        console.log(student.studentInitState);
        dispatch(setStudentBloqueo({ activo: bloqueado, mensaje: mensajeBloqueado }));

        if (inicializando) {
          console.log("Estudiante en proceso de inicializar");
          dispatch(setStudentInicializado(1));
        } else {
          console.log("Estudiante ya inicializado");
          dispatch(setStudentInicializado(2));
        }
        navigate("/");
      }

      if (res.error) {
        alert(res.error);
        setLogingIn(false);
        return;
      }
    } catch (err) {
      console.error("Login failed:", err);
    }
  };

  const handleInput = (e: any) => {
    if (e?.target?.value) {
      setEmail(e?.target?.value);
    }
  };

  return (
    <>
      <div className="page">
        <img src={logo} alt="logo Ditella" width={150} />
        {appState === "0" && role === "user" && (
          <>
            <p>Para realizar la inscripción deberá ingresar al sistema utilizando su mail de la universidad.</p>
            <p>Identifíquese usando su cuenta.</p>

            <div className="row">
              <SlButton className="full-width" size="medium" type="button" onClick={MicrosoftLogin}>
                <SlIcon slot="prefix" name="box-arrow-in-right"></SlIcon> Microsoft login
              </SlButton>
            </div>
          </>
        )}
        {(appState === "2" || role === "admin") && (
          <form onKeyDown={handleKeyDown}>
            <div className="row">
              <SlInput className="input-test" name="email" value={email} onSlInput={handleInput} required />
              <SlButton size="medium" type="button" onClick={handleSubmit} disabled={logingIn} loading={logingIn}>
                <SlIcon slot="prefix" name="box-arrow-in-right"></SlIcon>
              </SlButton>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default Login;
