import React, { useEffect } from "react";
import "./Layout.css";
import Header from "../Header/Header";
import Navbar from "../Navbar/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import TransitionModal from "../TransitionModal/TransitionModal";
import { selectStudent } from "../../reduxSlices/studentSlice";
import { useSelector } from "react-redux";
import { EstadoAlumnoCarrera } from "../../enums/alumnoCarrera.enum";
import ModeHeader from "../ModeHeader/ModeHeader";
import DesktopHeader from "../DesktopHeader/DesktopHeader";
import BlockedScreen from "../BlockedScreen/BlockedScreen";
import { selectModalsOverlays } from "../../reduxSlices/modalsOverlaysSlice";

interface IPropsLayout {
  children: React.ReactNode;
  error: boolean;
}

const DesktopLayout = ({ children, error }: IPropsLayout) => {
  const student = useSelector(selectStudent);
  const { modalFullOverlay } = useSelector(selectModalsOverlays);

  const location = useLocation();
  const navigate = useNavigate();

  console.log("LAYOUT ", student);
  console.log("LAYOUT ", student.bloqueo);

  useEffect(() => {
    if (error) {
      navigate("/error");
    }
  }, [error]);

  return (
    <div className="layout">
      <ModeHeader />
      {/* ------------------------------- HEADER Menu ------------------------------ */}
      {location.pathname === "/" && <DesktopHeader />}
      {/* -------------------------------- CHILDREN -------------------------------- */}
      {student.bloqueo.activo && student.bloqueo.mensaje !== "" ? (
        <BlockedScreen msg={student.bloqueo.mensaje} />
      ) : (
        <div className="layout-content">{children}</div>
      )}

      {/* -------------------------------------------------------------------------- */
      /*                                   MODALES                                  */
      /* -------------------------------------------------------------------------- */}
      {/* ------------------------------ MODAL General ----------------------------- */}
      {(student.id === 0 || student.email === "") &&
        (location.pathname === "/" || location.pathname === "/materias") && <TransitionModal open={true} text={"."} />}
      {/* --------------------------- MODAL Inicializando -------------------------- */}
      {student.studentInitState === EstadoAlumnoCarrera.INICIALIZACION_KV_PENDIENTE && (
        <TransitionModal
          open={true}
          text={"Inicializando la información del alumno. Esta operación puede demorar unos segundos."}
        />
      )}
      {/* --------------------------- Overlay espera -------------------------- */}
      {modalFullOverlay && <TransitionModal open={true} text={"Esperando respuesta..."} />}
    </div>
  );
};

export default DesktopLayout;
