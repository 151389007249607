import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    name: "",
    role: "user",
  },
  reducers: {
    updateAdminData: (state, action) => {
      state.name = action.payload.name
      state.role = action.payload.role
    },
  },
});

export const selectAdmin = (state: RootState) => state.admin

export const { updateAdminData } = adminSlice.actions;

export default adminSlice.reducer;
