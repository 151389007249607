import React, { useEffect, useRef, useState } from "react";
import "./DesktopCustomCalendar.css";
import {
  getFinalHour,
  getGridColumn,
  getInitialHour,
  getRowIndex,
} from "../../utils/calendarHelpers";
import { ICalendarSubjects, IInscribedSubject } from "../../types";
import { parseHours } from "../../utils";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import SlTooltip from "@shoelace-style/shoelace/dist/react/tooltip";
import ItemDetail from "./DesktopItemDetail";
import Modal from "../Modal/Modal";
import useUnsubscribeSection from "../../hooks/useUnsubscribeSection";
import { useSelector } from "react-redux";
import { selectEditionMode } from "../../reduxSlices/editorModeSlice";
import { BsExclamationLg } from "react-icons/bs";


interface IPropsCustomCalendar {
  subjects: ICalendarSubjects[];
  onSelectedItem: (id: number) => any;
}

interface ISelectedItemIds {
  idMateria: number;
  idCurso: number;
}

const getWidthPercentage = (value: number): string => {
  if (value === 0) {
      return "100%";
  } else if (value === 1) {
      return "75%";
  } else if (value === 2) {
      return "50%";
  } else {
      return "25%";
  }
}

const DesktopCustomCalendar = ({ subjects, onSelectedItem }: IPropsCustomCalendar) => {
  const [selectedView, setSelectedView] = useState("semanal");
  const [rowHeight, setRowHeight] = useState<number>(0);
  const [selectedItemIds, setSelectedItemIds] =
    useState<ISelectedItemIds | null>(null);
  const [selectedItem, setSelectedItem] = useState<IInscribedSubject | null>(
    null
  );
  const [showModal, setShowModal] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const editMode = useSelector(selectEditionMode);
  const unsubscribeSection = useUnsubscribeSection();
  const initialHour = getInitialHour(subjects);
  const finalHour = getFinalHour(subjects);
  const hours = (finalHour - initialHour) / 100;
  const minutes = hours * 60;
  const rowQty = minutes / 5;
  
  useEffect(() => {
    if (containerRef.current) {
      const handleResize = () => {
        const containerHeight = containerRef.current!.offsetHeight;
        const paddingsAndHeadersHeight = 120;
        setRowHeight((containerHeight - paddingsAndHeadersHeight) / rowQty);
      };

      handleResize();
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [containerRef, rowQty]);

  useEffect(() => {
    if (selectedItemIds !== null) {
      const item = onSelectedItem(selectedItemIds.idMateria);
      setSelectedItem(item);
    }
  }, [selectedItemIds]);

  const renderOverlapWarning = () => {
    return (
      <SlTooltip content="Horarios superpuestos" trigger="click">
        <div style={{ color: "white", width: "20px", height: "20px", display:"flex", justifyContent:"center", alignItems:"center", zIndex: 92, border:"1px solid white",backgroundColor: "#f52323", padding: "1px", borderRadius:"50px" }}>
        <BsExclamationLg />
        </div>
      </SlTooltip>
    );
  };

  const generateCourseDictionary = (subjects: ICalendarSubjects[])=> {
    const courseDictionary: Record<number, number> = {};
    let currentNumber = 0;
  
    subjects.forEach(subject => {
      if (!(subject.idCurso in courseDictionary)) {
        courseDictionary[subject.idCurso] = currentNumber;
        currentNumber++;
      }
    });
  
    return courseDictionary;
  }

  const renderInscribedSubjects = (subjets: ICalendarSubjects[]) => {
    const colors = ["#2277DA", "#FF4789", "#FF7A30", "#FBC54F", "#97D86C", "#2ED4C6", "#C47DDD","#2277DA", "#FF4789", "#FF7A30", "#FBC54F", "#97D86C", "#2ED4C6", "#C47DDD"];

    console.log(subjects);
    const  diccionariocolors = generateCourseDictionary(subjects)
    console.log("RE Render calendar items");
    
    
    return subjets.map((m) => {
      const initialRow = getRowIndex(m.desde, initialHour);
      const finalRow = getRowIndex(m.hasta, initialHour);
      const color = colors[diccionariocolors[m.idCurso]];
      return (
        <div
          key={"weekly" + m.idMateria + m.dia + m.desde + m.profesor+m.idCurso}
          className={`desktop-custom-calendar-item ${m.realInscribed ? "" : "desktop-demo-subject"}`}
          // onClick={() =>
          //   setSelectedItemIds({
          //     idMateria: Number(m.idMateria),
          //     idCurso: m.idCurso,
          //   })
          // }
          style={{
            backgroundColor: m.realInscribed ? colors[diccionariocolors[m.idCurso]]: "white",
            border: `2px solid ${color}`,
            borderLeftWidth: "8px",
            borderLeftColor: color,
            gridColumn: getGridColumn(m.dia),
            gridRow: `${initialRow}/${finalRow}`,
            width: getWidthPercentage(m.superpuesta),
            justifySelf: "end"
          }}
        >
          {m.superpuesta>0 ? renderOverlapWarning() : null}
          <p className={`desktop-custom-calendar-item-text  ${m.realInscribed ? "" : "desktop-demo-subject-title"}`} >{m.curso.substring(0, 5)}</p>
        </div>
      );
    });
  };

  const renderInscribedSubjectsByDay = (
    subjets: ICalendarSubjects[],
    day: string
  ) => {
    const filteredSubjects = subjects.filter((s) => s.dia === day);
    return filteredSubjects.map((m, index) => {
      const initialRow = getRowIndex(m.desde, initialHour);
      const finalRow = getRowIndex(m.hasta, initialHour);
      return (
        <div
          key={"daily" + m.idMateria + m.dia + m.desde + m.profesor}
          className="desktop-custom-calendar-item-day"
          onClick={() =>
            setSelectedItemIds({
              idMateria: Number(m.idMateria),
              idCurso: m.idCurso,
            })
          }
          style={{
            backgroundColor: m.colors.background,
            ...(m.warning
              ? { border: "2px #ff00008f solid" }
              : { borderLeft: `2px ${m.colors.border} solid` }),
            color: m.colors.border,
            gridRow: `${initialRow}/${finalRow}`,
            gridColumn: "2/7",
          }}
        >
          {m.warning ? renderOverlapWarning() : null}
          <p className="desktop-custom-calendar-item-day-text">{m.curso}</p>
          <p className="desktop-custom-calendar-item-day-time">
            {parseHours(m.desde)}hs a {parseHours(m.hasta)}hs
          </p>
        </div>
      );
    });
  };

  const renderSeparators = () => {
    const separatorsArr = Array.from({ length: hours + 1 }, (_, index) => {
      const hour = Math.floor((initialHour + index * 100) / 100);
      const formattedTime = `${hour.toString().padStart(2, "0")}:00`;
      return formattedTime;
    });
    // const separatorsArr = ["13:45","15:20","17:15", "19:00"]
    // console.log("separatorrrrrrrrrrrr",separatorsArr);
    

    return separatorsArr.map((s, index) => {
      const intervalsPerHour = 12;
      return (
        <div
          className="desktop-custom-calendar-separator-hour"
          key={s}
          style={{ top: index * intervalsPerHour * rowHeight }}
        >
          <p className="desktop-custom-calendar-separator-hour-label">{s} hs</p>
        </div>
      );
    });
  };

  const renderSeparators2 = () => {
    const separatorsArr = Array.from({ length: hours * 2 + 1 }, (_, index) => {
      const hour = Math.floor((initialHour + index * 100) / 100);
      const formattedTime = `${hour.toString().padStart(2, "0")}:00`;
      return formattedTime;
    });

    return separatorsArr.map((s, index) => {
      const intervalsPerHour = 6;
      return (
        <div
          className="custom-calendar-separator-half-hour"
          key={s}
          style={{ top: index * intervalsPerHour * rowHeight }}
        >
          <p className="custom-calendar-separator-half-hour-label"></p>
        </div>
      );
    });
  };

  const renderDays = () => {
    return (
      <div className="calendar-days">
        <p
          className={`calendar-days-item ${
            selectedView === "Lunes" ? "calendar-days-item-active" : ""
          }`}
          onClick={() => setSelectedView("Lunes")}
        >
          Lun
        </p>
        <p
          className={`calendar-days-item ${
            selectedView === "Martes" ? "calendar-days-item-active" : ""
          }`}
          onClick={() => setSelectedView("Martes")}
        >
          Mar
        </p>
        <p
          className={`calendar-days-item ${
            selectedView === "Miércoles" ? "calendar-days-item-active" : ""
          }`}
          onClick={() => setSelectedView("Miércoles")}
        >
          Mié
        </p>
        <p
          className={`calendar-days-item ${
            selectedView === "Jueves" ? "calendar-days-item-active" : ""
          }`}
          onClick={() => setSelectedView("Jueves")}
        >
          Jue
        </p>
        <p
          className={`calendar-days-item ${
            selectedView === "Viernes" ? "calendar-days-item-active" : ""
          }`}
          onClick={() => setSelectedView("Viernes")}
        >
          Vie
        </p>
      </div>
    );
  };

  const handleItemDetailClose = () => {
    setSelectedItemIds(null);
    setSelectedItem(null);
  };

  return (
    <div className="desktop-calendar-container" ref={containerRef}>
       <div className="desktop-calendar-days-row">
        <div className="desktop-calendar-days-item"></div>
        <div className="desktop-calendar-days-item">Lunes</div>
        <div className="desktop-calendar-days-item">Martes</div>
        <div className="desktop-calendar-days-item">Miercoles</div>
        <div className="desktop-calendar-days-item">Jueves</div>
        <div className="desktop-calendar-days-item">Viernes</div>
       </div>
      <div
        className="desktop-custom-calendar"
        style={{
          display: "grid",
          //height: rowHeight * rowQty,
          gridTemplateColumns: "1fr repeat(5, 2fr)",
          gridTemplateRows: `repeat(${Math.round(rowQty)}, ${rowHeight}px)`,
          gridAutoRows: `${rowHeight}px`,
          columnGap: "5px"
        }}
      >
          {renderInscribedSubjects(subjects)}
        {renderSeparators()}
        {renderSeparators2()}
      </div>
    </div>
  );
};

export default DesktopCustomCalendar;
