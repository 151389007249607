import "./BlockedScreen.css";
interface Props {
  msg: string;
}
export default function BlockedScreen({ msg }: Props) {
  return (
    <div className="blocked-screen-container">
      <p>⚠</p>
      <h2>El acceso a la plataforma se encuentra bloqueado.</h2>
      <p>{msg ? msg : "Consulte con el departamente de Alumnos su situación."}</p>
    </div>
  );
}
