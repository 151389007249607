import React, { useEffect } from "react";
import "./Layout.css";
import Header from "../Header/Header";
import Navbar from "../Navbar/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import TransitionModal from "../TransitionModal/TransitionModal";
import { selectStudent } from "../../reduxSlices/studentSlice";
import { useSelector } from "react-redux";
import { EstadoAlumnoCarrera } from "../../enums/alumnoCarrera.enum";
import ModeHeader from "../ModeHeader/ModeHeader";
import BlockedScreen from "../BlockedScreen/BlockedScreen";

interface IPropsLayout {
  children: React.ReactNode;
  error: boolean;
}

const MobileLayout = ({ children, error }: IPropsLayout) => {
  const student = useSelector(selectStudent);

  const location = useLocation();
  const navigate = useNavigate();
  console.log(error);
  

  useEffect(() => {
    if (error) {
      navigate("/error");
    }
  }, [error]);

  return (
    <div className="layout">
      <ModeHeader />
      {/* ----------------------- Header Materias Inscriptas ----------------------- */}
      {location.pathname === "/" || location.pathname === "/materias" ? (
        <div className="layout-header">
          <Header />
          {JSON.stringify(student.bloqueo)}
        </div>
      ) : null}
      
      {/* -------------------------------- CHILDREN -------------------------------- */}
      {student.bloqueo.activo ? <BlockedScreen msg={student.bloqueo.mensaje} /> : <div className="layout-content">{children}</div>}
      {/* --------------------- Menu Barra Navegacion inferior --------------------- */}
      {(location.pathname === "/" || location.pathname === "/materias") && !student.bloqueo.activo ? (
        <div className="layout-navbar">
          <Navbar />
        </div>
      ) : null}
      {/* -------------------------------------------------------------------------- */
      /*                                   MODALES                                  */
      /* -------------------------------------------------------------------------- */}
      {/* ------------------------------ MODAL General ----------------------------- */}
      {(student.id === 0 || student.email === "") &&
        (location.pathname === "/" || location.pathname === "/materias") && (
          <TransitionModal open={true} text={"."} />
        )}
      {/* --------------------------- MODAL Inicializando -------------------------- */}
      {(student.studentInitState ===
        EstadoAlumnoCarrera.INICIALIZACION_KV_PENDIENTE) && (
        <TransitionModal
          open={true}
          text={
            "Inicializando la información del alumno. Esta operación puede demorar unos segundos."
          }
        />
      )}
    </div>
  );
};

export default MobileLayout;
