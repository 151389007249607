import React, { Fragment, useState } from "react";
import "./SubjectItem.css";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import SlCheckbox from "@shoelace-style/shoelace/dist/react/checkbox";
import IconText from "../IconText/IconText";
import SubjectDetail from "../SubjectDetail/SubjectDetail";
import SubjectDescription from "../SubjectDescription/SubjectDescription";
import SlDivider from "@shoelace-style/shoelace/dist/react/divider";
import {
  IOfferedSubjectByType,
  IOfferedSubjectData,
  IQuota,
  ISelectedSection,
  ISubjectData,
  ISubjectsByType,
} from "../../types";
import { getQuotaColor } from "../../utils";
import { selectEditionMode } from "../../reduxSlices/editorModeSlice";
import { useSelector } from "react-redux";

interface IPropsSubjectItem {
  id: number;
  subject: string;
  subjectId: string;
  section: string;
  selected: boolean;
  subjectData: IOfferedSubjectData;
  quota: IQuota;
  onChange: (data: ISelectedSection) => void;
  disabled: boolean;
}

const SubjectItem = ({
  subject,
  section,
  id,
  selected,
  subjectData,
  quota,
  onChange,
  subjectId,
  disabled,
}: IPropsSubjectItem) => {
  const handlCheckboxChange = () => {
    onChange({
      tic: subjectData.reserveData.tic,
      tim: subjectData.reserveData.tim,
      idC: subjectData.reserveData.idC,
      idA: subjectData.reserveData.idA,
      idS: subjectData.reserveData.idS,
      section,
      subjectId: subjectId,
    });
  };
  const [expanded, setExpanded] = useState(false);
  const { on } = useSelector(selectEditionMode);

  const renderHeader = () => {
    return (
      <div className="subject-item-header">
        {on ? (
          <SlCheckbox
            value={String(id)}
            checked={selected}
            className="subject-item-header-checkbox"
            onSlChange={handlCheckboxChange}
            disabled={disabled}
          >
            <div className="subject-item-header-checkbox-label">
              <div className="subject-item-header-checkbox-label-text">
                <p className="subject-item-header-checkbox-label-text-title">
                  {section}
                </p>
              </div>
            </div>
          </SlCheckbox>
        ) : (
          <p className="subject-item-header-checkbox-label-text-title">
            {section}
          </p>
        )}

        <div className="subject-item-header-right">
          <div className="subject-item-header-right-quota">
            {quota && quota?.qty < 0 ? (
              <span className="subject-no-available">SIN INFO</span>
            ) : (
              <>
                <IconText
                  icon="person-circle"
                  text={quota && quota.qty ? quota.qty.toString() : "-"}
                  color={quota && getQuotaColor(quota.availability)}
                  textSize={10}
                  iconHeight={12}
                />
                <SlIcon
                  name={`${expanded ? "chevron-up" : "chevron-down"}`}
                  className="subject-item-header-right-icon"
                  onClick={() => setExpanded(!expanded)}
                ></SlIcon>
              </>
            )}
          </div>
          {subjectData.reserveData.tic && subjectData.reserveData.tim && (
            <div className="subject-item-header-right-quotaType">
              <p>{subjectData.reserveData.tic}</p>
              <p>{subjectData.reserveData.tim}</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderSubjectDetails = (subjectData: IOfferedSubjectByType[]) => {
    return subjectData.map((s, index) => {
      return (
        <Fragment key={s.subjectType + index}>
          <SlDivider />
          <SubjectDetail
            subjectType={s.subjectType}
            subjectInfo={s.subjectInfo}
          />
          {subjectData.length === index + 1 ? <SlDivider /> : null}
        </Fragment>
      );
    });
  };

  return (
    <div className={`subject-item ${selected ? "subject-item-selected" : ""}`}>
      {renderHeader()}
      <div
        className={`subject-item-data-wrapper ${
          expanded ? "subject-item-data-wrapper-expanded" : ""
        }`}
      >
        <div className="subject-item-data-animation">
          <div
            className={`subject-item-data-transform-wrapper ${
              expanded ? "subject-item-data-transform-wrapper-expanded" : ""
            }`}
          >
            <div className={`subject-item-data`}>
              {subjectData && subjectData.subjectsByType ? (
                renderSubjectDetails(subjectData.subjectsByType)
              ) : (
                <></>
              )}
              <div className="subject-item-data-description">
                {subjectData ? (
                  <SubjectDescription
                    title={"Descripción"}
                    description={
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dolor tellus, tempor eu finibus non, pellentesque at tortor. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nam ut facilisis lectus. Curabitur dignissim ornare nisi in ullamcorper. Donec maximus, justo sit amet hendrerit porta, neque libero feugiat nulla, ut sagittis diam ante id est."
                    }
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubjectItem;
