import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { ICalendarSubjects, IInscribedSubject, IOfferedSubject } from "../types";
import { updateCupoCursoBuilder } from "../reduxAsyncThunks/subjects";

interface ISelectedOfferedSubjects {
  idS: number;
  subjectId: number;
}

export interface ISubjectsSliceState {
  inscribedSubjects: IInscribedSubject[] | null;
  offeredSubjects: IOfferedSubject[] | null;
  selectedOfferedSubjects: ISelectedOfferedSubjects[];
  loadingRequestInTransit: boolean;
  subjectsQuotas: any;
  pinnedSubjectsList: number[];
  calendarSubjectsList: ICalendarSubjects[];
  calendarSubjectsDemoCount: number;
}

const initialState: ISubjectsSliceState = {
  inscribedSubjects: null,
  offeredSubjects: null,
  selectedOfferedSubjects: [],
  loadingRequestInTransit: false,
  subjectsQuotas: {},
  pinnedSubjectsList: [],
  calendarSubjectsList: [],
  calendarSubjectsDemoCount: 0,
};

export const subjectsSlice = createSlice({
  name: "subjects",
  initialState,
  reducers: {
    setInscribedSubjects: (state, action) => {
      state.inscribedSubjects = action.payload;
    },
    setOfferedSubjects: (state, action) => {
      state.offeredSubjects = action.payload;
    },
    addInscribedSubject: (state, action) => {
      if (state.inscribedSubjects) {
        state.inscribedSubjects = [...state.inscribedSubjects, action.payload];
      }
    },
    removeInscribedSubject: (state, action) => {
      if (state.inscribedSubjects) {
        const newInscribedSubjects = state.inscribedSubjects.filter((i) => i.sections[0].idCurso !== action.payload);
        state.inscribedSubjects = newInscribedSubjects;
      }
    },
    addSelectedOfferedSubject: (state, action) => {
      state.selectedOfferedSubjects = [...state.selectedOfferedSubjects, action.payload];
    },
    removeSelectedOfferedSubject: (state, action) => {
      const newSelectedOfferedSubjects = state.selectedOfferedSubjects.filter((i) => i.idS !== action.payload);
      state.selectedOfferedSubjects = newSelectedOfferedSubjects;
    },
    clearSelectedOfferedSubject: (state) => {
      state.selectedOfferedSubjects = [];
    },
    setLoadingRequestInTransit: (state, { payload }) => {
      state.loadingRequestInTransit = payload;
    },
    setSubjectsQuota: (state, action) => {
      state.subjectsQuotas = action.payload;
    },
    setPinnedSubjectsList: (state, action) => {
      state.pinnedSubjectsList = action.payload;
    },
    addCalendarSubject: (state, action) => {
      if (state.calendarSubjectsList) {
        state.calendarSubjectsList = [...state.calendarSubjectsList, action.payload];
      }
    },
    removeCalendarSubject: (state, action) => {
      if (state.calendarSubjectsList) {
        const newCalendarSubjects = state.calendarSubjectsList.filter((i) => i.idCurso !== action.payload);
        state.calendarSubjectsList = newCalendarSubjects;
      }
    },
    addCalendarSubjectDemoCount: (state) => {      
        state.calendarSubjectsDemoCount = state.calendarSubjectsDemoCount+1;
    },
    subtractCalendarSubjectDemoCount: (state) => {      
      if(state.calendarSubjectsDemoCount>0){
        state.calendarSubjectsDemoCount = state.calendarSubjectsDemoCount-1;
      }
    },
  },
  extraReducers(builder) {
    updateCupoCursoBuilder(builder);
  },
});

export const selectInscribedSubjects = (state: RootState) => state.subjects.inscribedSubjects;

export const selectOfferedSubjects = (state: RootState) => state.subjects.offeredSubjects;

export const selectSelectedOfferedSubjects = (state: RootState) => state.subjects.selectedOfferedSubjects;

export const selectLoadingRequestInTransit = (state: RootState) => state.subjects.loadingRequestInTransit;

export const selectSubjectsQuotas = (state: RootState) => state.subjects.subjectsQuotas;

export const selectPinnedSubjectsList = (state: RootState) => state.subjects.pinnedSubjectsList;

export const selectCalendarSubjectsList = (state: RootState) => state.subjects.calendarSubjectsList;

export const selectCalendarSubjectsDemoCount = (state: RootState) => state.subjects.calendarSubjectsDemoCount;

export const {
  setInscribedSubjects,
  setOfferedSubjects,
  addInscribedSubject,
  removeInscribedSubject,
  addSelectedOfferedSubject,
  removeSelectedOfferedSubject,
  clearSelectedOfferedSubject,
  setLoadingRequestInTransit,
  setSubjectsQuota,
  setPinnedSubjectsList,
  addCalendarSubject,
  removeCalendarSubject,
  addCalendarSubjectDemoCount,
  subtractCalendarSubjectDemoCount
} = subjectsSlice.actions;

export default subjectsSlice.reducer;
