import "./SubjectDetailsModal.css";
import {
  IInscribedSubjectSection,
} from "../../types";
import { parseHours } from "../../utils";
import SlDivider from "@shoelace-style/shoelace/dist/react/divider";

interface SubjectDetailsProp {
  close: () => void;
  sections: IInscribedSubjectSection[];
}

export default function SubjectDetailsModal({
  close,
  sections,
}: SubjectDetailsProp) {
  const ordenados = ordenarPorDia(sections);

  return (
    <div className="custom-detail-modal" onClick={close}>
      <div
        className="inner-modal-details"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <p className="detail-modal-title">
          <strong>{sections[0].titleMateria}</strong>
        </p>
        {ordenados.map((section: IInscribedSubjectSection, i: number) => (
          <div className="detail-modal-section" key={section.idCurso+i}>
            {i > 0 &&
              ordenados[i - 1].subjectData.dia !== section.subjectData.dia && (
                <SlDivider className="detail-modal-divider" />
              )}
            <p>
              <span className="detail-modal-day">
                {section.subjectData.dia}
              </span>
              {parseHours(Number(section.subjectData.desde))} a{" "}
              {parseHours(Number(section.subjectData.hasta))} hs
            </p>
            <p>
              <span className="detail-modal-label">Profesor:</span>{" "}
              {section.subjectData.profesor}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

function ordenarPorDia(listado: IInscribedSubjectSection[]) {
  const diasDeLaSemana = [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
  ];

  const sectionsCopy = [...listado];

  return sectionsCopy.sort(
    (a: IInscribedSubjectSection, b: IInscribedSubjectSection) => {
      return (
        diasDeLaSemana.indexOf(a.subjectData.dia) -
        diasDeLaSemana.indexOf(b.subjectData.dia)
      );
    }
  );
}
