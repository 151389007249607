import React from "react";
import "./Warning.css";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

const WarningIcon = () => {
  return (
    <svg
      width="92"
      height="92"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill="#F5A800" />
      <path
        d="M11.1427 14.2002C10.6518 14.2002 10.2539 13.8023 10.2539 13.3114V6.5294C10.2539 6.03854 10.6518 5.64062 11.1427 5.64062C11.6335 5.64062 12.0315 6.03854 12.0315 6.5294V13.3114C12.0315 13.8023 11.6335 14.2002 11.1427 14.2002ZM11.1427 16.8235C10.6567 16.8235 10.2539 16.4295 10.2539 15.9436C10.2539 15.4576 10.6567 15.0636 11.1427 15.0636C11.6286 15.0636 12.0315 15.4576 12.0315 15.9436C12.0315 16.4295 11.6286 16.8235 11.1427 16.8235Z"
        fill="white"
      />
    </svg>
  );
};

const Warning = () => {
  return (
    <div className="warning-component">
      <WarningIcon />
      <div className="warning-component-text">
        <h3>Aplicación Abierta en Otra Pestaña</h3>
        <p>
          Parece que esta aplicación ya está abierta en otra pestaña del
          navegador. Por favor, vuelve a esa pestaña para continuar usando la
          aplicación.
        </p>
      </div>
    </div>
  );
};

export default Warning;
