import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import React, { useEffect, useRef, useState } from "react";
import "./DesktopSelectedSubjects.css";
import Modal from "../Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { selectInscribedSubjects, selectLoadingRequestInTransit } from "../../reduxSlices/subjectsSlice";
import { selectEditionMode } from "../../reduxSlices/editorModeSlice";
import useUnsubscribeSection from "../../hooks/useUnsubscribeSection";
import { updateDetailSubject } from "../../reduxSlices/detailSubjectSlice";
import { IInscribedSubject, IInscribedSubjectSection } from "../../types";
import Spinner from "../Spinner/Spinner";
import SlDivider from "@shoelace-style/shoelace/dist/react/divider";
import { selectConfirmationsFlag } from "../../reduxSlices/parametersSlice";

interface ISelectedSubject {
  title: string;
  sectionId: number;
  subjectId: number;
  active: boolean;
}

interface ISubjectToUnsub {
  sectionId: number;
  section: string;
}

const DesktopSelectedSubjects = () => {
  const [open, setOpen] = useState(false);
  const loadingRequestInTransit = useSelector(selectLoadingRequestInTransit);
  const [subjectToUnsub, setSubjectToUnsub] = useState<ISubjectToUnsub>();
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const confirmationsEnabled = useSelector(selectConfirmationsFlag);

  const unsubscribeSection = useUnsubscribeSection();

  console.log(inscribedSubjects);
  

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */
  const handleSetDetailSubject = (id: number) => {
    const sections =
      inscribedSubjects?.filter((subject: IInscribedSubject) => subject.idCurso === id)[0].sections || [];
    if (sections?.length) {
      dispatch(updateDetailSubject({ sections }));
    }
  };

  const handleUnsuscribeSection = async (idCurso: number) => {
    await unsubscribeSection(idCurso);
  };

  const handleTrashSubject = async (subject: IInscribedSubject) => {
    if (confirmationsEnabled) {
      setSubjectToUnsub({
        sectionId: subject.idCurso,
        section: subject.title,
      });
    } else {
      await handleUnsuscribeSection(subject.idCurso);
    }
  };

  const renderSelectedSubjects = (subjects: IInscribedSubject[]) => {
    return subjects.map((s, index) => {
      const isLastItem = subjects.length === index + 1;
      return (
      <div
      className="desktop-item-wrapper"
        key={s.title + index}
      >
        <div
        className="desktop-inscribed-subject-item"
        >
          <div
           className="desktop-selected-subjects-item-left">
            <h2 className="desktop-selected-subject-item-left-title">{s.title}</h2>
            <p>{s.sections[0].subjectData.profesor ? s.sections[0].subjectData.profesor : "---"}</p>
          </div>
          <div className="desktop-selected-subject-item-right">
            {/* <SlIcon
              name="eye"
              className="selected-subjects-item-right-icon-edit"
              onClick={() => handleSetDetailSubject(s.idCurso)}
            ></SlIcon> */}
            <button 
              onClick={() => handleTrashSubject(s)}
            className="desktop-btn-unsuscribe-subject">
            Desinscribirme
            </button>
          </div>
        </div>
        {!isLastItem && <SlDivider />}
        </div>

      );
    });
  };


  return (
    <div className="desktop-inscribed-subjects" ref={containerRef}>
      <div className="desktop-inscribed-subjects-count">
        {loadingRequestInTransit ? (
          <Spinner style={{ fontSize: "1.2rem" }} />
        ) : (
          <p className="desktop-inscribed-quantity">{!inscribedSubjects ? 0 : inscribedSubjects.length}</p>
        )}
        <p>Materias inscriptas</p>
      </div>
      <hr />
      <div className="desktop-inscribed-subjects-list">
        {inscribedSubjects ? renderSelectedSubjects(inscribedSubjects) : null}
      </div>
      {subjectToUnsub && subjectToUnsub.sectionId && (
        <Modal
          open={subjectToUnsub.sectionId ? true : false}
          onClose={() => setOpen(false)}
          message={`¿Estás seguro de que deseas eliminar ${subjectToUnsub.section} de tu oferta académica?
          El cupo quedará reservado hasta que confirmes los cambios.`}
          okButton={{
            label: "Continuar",
            buttonFn: () => {
              handleUnsuscribeSection(subjectToUnsub.sectionId);
              setSubjectToUnsub(undefined);
            },
          }}
          closeButton={{
            label: "Cancelar",
            buttonFn: () => setSubjectToUnsub(undefined),
          }}
        />
      )}
    </div>
  );
};

export default DesktopSelectedSubjects;
