import { useState } from "react";
import DesktopOfferList from "../DesktopOfferList/DesktopOfferList";
import DesktopSelectedSubjects from "../DesktopSelectedSubjects/DesktopSelectedSubjects";
import "./DesktopMain.css";
import DesktopCalendar from "../DesktopCalendar/DesktopCalendar";

function DesktopMain() {
  const [tab, setTab] = useState("materias");

  const handleDesktopTab = (tab: string) => {
    setTab(tab);
  };
  return (
    <div className="desktop-main">
      <div className="desktop-left">
        <div className="desktop-inscribed-calendar-tab">
          <button
            className={`desktop-left-tab ${tab === "materias" ? "desktop-left-tab-active" : false}`}
            onClick={() => handleDesktopTab("materias")}
          >
            Mis cursos
          </button>
          <button
            className={`desktop-left-tab ${tab === "calendario" ? "desktop-left-tab-active" : false}`}
            onClick={() => handleDesktopTab("calendario")}
          >
            Calendario
          </button>
        </div>
        {tab === "materias" && <DesktopSelectedSubjects />}
        {tab === "calendario" && <DesktopCalendar/>}
      </div>
      <div className="right">
        <DesktopOfferList />
      </div>
    </div>
  );
}

export default DesktopMain;
