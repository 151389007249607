import React, { Fragment } from "react";
import "./SubjectDetail.css";
import { parseHours } from "../../utils";
import { IOfferedSubjectInfo, ISubjectInfo } from "../../types";


interface IPropsSubjectDetail {
  subjectType: string;
  subjectInfo: IOfferedSubjectInfo[];
}

const SubjectDetail = ({ subjectType, subjectInfo }: IPropsSubjectDetail) => {

  const renderSubjectValues = (values: any) => {
    return (
      <div key={values.id} className="subjects-value-box">
        <div>
          <p className="subject-detail-data-info-label">Horario</p>
          <p className="subject-detail-data-info-value">
            <span className="text-bold">{values.dia}</span> {`${parseHours(values.desde)} - ${parseHours(
              values.hasta
            )} hs`}
          </p>
        </div>
        <div>
          <p className="subject-detail-data-info-label">Profesores</p>
          {values.profesores.map((profe: string, index:number) => {
            if(profe){
              return <p key={profe+index} className="subject-detail-data-info-value">• {profe}</p>
            }
          })}
        </div>
      </div>
    );
  };

  const renderSubjectItem = (data: IOfferedSubjectInfo[]) => {
    return data.map((d, index) => {
      return (
        <Fragment key={d.title + index}>
          <div
            className={`subject-detail-data-info  ${
              index % 2 ? "" : "vertical-separator"
            }`}
          >
            {renderSubjectValues(d.values)}
          </div>
        </Fragment>
      );
    });
  }; 

  return (
    <div className="subject-detail">
      <h2 className="subject-detail-type">{subjectType}</h2>
      <div className="subject-detail-data">
        {renderSubjectItem(subjectInfo)}
      </div>
    </div>
  );
};

export default SubjectDetail;
