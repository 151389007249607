import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { conf } from "./utils/config";
import { Provider } from "react-redux";
import store from "./app/store";
import { msalConfig } from "./auth/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const msalInstance = new PublicClientApplication(msalConfig);

const defaultConfig: AxiosRequestConfig = {
  baseURL: conf("BASE_URL"),
};

axios.interceptors.request.use(
  (config) => {
    config.baseURL = defaultConfig.baseURL;
    const token = localStorage.getItem("token");
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      } as AxiosRequestHeaders;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App />
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
