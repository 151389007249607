import React, { useEffect, useRef, useState } from "react";
import "./CustomCalendar.css";
import {
  getFinalHour,
  getGridColumn,
  getInitialHour,
  getRowIndex,
} from "../../utils/calendarHelpers";
import { ICalendarSubjects, IInscribedSubject } from "../../types";
import { parseHours } from "../../utils";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import SlTooltip from "@shoelace-style/shoelace/dist/react/tooltip";
import ItemDetail from "./ItemDetail";
import Modal from "../Modal/Modal";
import useUnsubscribeSection from "../../hooks/useUnsubscribeSection";
import { useSelector } from "react-redux";
import { selectEditionMode } from "../../reduxSlices/editorModeSlice";

interface IPropsCustomCalendar {
  subjects: ICalendarSubjects[];
  onSelectedItem: (id: number) => any;
}

interface ISelectedItemIds {
  idMateria: number;
  idCurso: number;
}

const CustomCalendar = ({ subjects, onSelectedItem }: IPropsCustomCalendar) => {
  const [selectedView, setSelectedView] = useState("semanal");
  const [rowHeight, setRowHeight] = useState<number>(0);
  const [selectedItemIds, setSelectedItemIds] =
    useState<ISelectedItemIds | null>(null);
  const [selectedItem, setSelectedItem] = useState<IInscribedSubject | null>(
    null
  );
  const [showModal, setShowModal] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const editMode = useSelector(selectEditionMode);
  const unsubscribeSection = useUnsubscribeSection();
  const initialHour = getInitialHour(subjects);
  const finalHour = getFinalHour(subjects);
  const hours = (finalHour - initialHour) / 100;
  const minutes = hours * 60;
  const rowQty = minutes / 5;  

  useEffect(() => {
    if (containerRef.current) {
      const handleResize = () => {
        const containerHeight = containerRef.current!.offsetHeight;
        const paddingsAndHeadersHeight = 120;
        setRowHeight((containerHeight - paddingsAndHeadersHeight) / rowQty);
      };

      handleResize();
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [containerRef, rowQty]);

  useEffect(() => {
    if (selectedItemIds !== null) {
      const item = onSelectedItem(selectedItemIds.idMateria);
      setSelectedItem(item);
    }
  }, [selectedItemIds]);

  const renderOverlapWarning = () => {
    return (
      <SlTooltip content="Horarios superpuestos" trigger="click">
        <div style={{ color: "#f52323", width: "fit-content", zIndex: 92 }}>
          <SlIcon name="exclamation-triangle"></SlIcon>
        </div>
      </SlTooltip>
    );
  };

  const renderInscribedSubjects = (subjets: ICalendarSubjects[]) => {
    return subjets.map((m) => {
      const initialRow = getRowIndex(m.desde, initialHour);
      const finalRow = getRowIndex(m.hasta, initialHour);
      return (
        <div
          key={"weekly" + m.idMateria + m.dia + m.desde + m.profesor}
          className={`custom-calendar-item`}
          onClick={() =>
            setSelectedItemIds({
              idMateria: Number(m.idMateria),
              idCurso: m.idCurso,
            })
          }
          style={{
            backgroundColor: m.colors.background,
            ...(m.warning
              ? { border: "2px #c110108f solid" }
              : { borderLeft: `2px ${m.colors.border} solid` }),
            color: m.colors.border,
            gridColumn: getGridColumn(m.dia),
            gridRow: `${initialRow}/${finalRow}`,
          }}
        >
          {m.warning ? renderOverlapWarning() : null}
          <p className="custom-calendar-item-text">{m.curso.substring(0, 3)}</p>
        </div>
      );
    });
  };

  const renderInscribedSubjectsByDay = (
    subjets: ICalendarSubjects[],
    day: string
  ) => {
    const filteredSubjects = subjects.filter((s) => s.dia === day);
    return filteredSubjects.map((m, index) => {
      const initialRow = getRowIndex(m.desde, initialHour);
      const finalRow = getRowIndex(m.hasta, initialHour);
      return (
        <div
          key={"daily" + m.idMateria + m.dia + m.desde + m.profesor}
          className="custom-calendar-item-day"
          onClick={() =>
            setSelectedItemIds({
              idMateria: Number(m.idMateria),
              idCurso: m.idCurso,
            })
          }
          style={{
            backgroundColor: m.colors.background,
            ...(m.warning
              ? { border: "2px #ff00008f solid" }
              : { borderLeft: `2px ${m.colors.border} solid` }),
            color: m.colors.border,
            gridRow: `${initialRow}/${finalRow}`,
            gridColumn: "2/7",
          }}
        >
          {m.warning ? renderOverlapWarning() : null}
          <p className="custom-calendar-item-day-text">{m.curso}</p>
          <p className="custom-calendar-item-day-time">
            {parseHours(m.desde)}hs a {parseHours(m.hasta)}hs
          </p>
        </div>
      );
    });
  };

  const renderSeparators = () => {
    const separatorsArr = Array.from({ length: hours + 1 }, (_, index) => {
      const hour = Math.floor((initialHour + index * 100) / 100);
      const formattedTime = `${hour.toString().padStart(2, "0")}:00`;
      return formattedTime;
    });

    return separatorsArr.map((s, index) => {
      const intervalsPerHour = 12;
      return (
        <div
          className="custom-calendar-separator-hour"
          key={s}
          style={{ top: index * intervalsPerHour * rowHeight }}
        >
          <p className="custom-calendar-separator-hour-label">{s} hs</p>
        </div>
      );
    });
  };

  const renderSeparators2 = () => {
    const separatorsArr = Array.from({ length: hours * 2 + 1 }, (_, index) => {
      const hour = Math.floor((initialHour + index * 100) / 100);
      const formattedTime = `${hour.toString().padStart(2, "0")}:00`;
      return formattedTime;
    });

    return separatorsArr.map((s, index) => {
      const intervalsPerHour = 6;
      return (
        <div
          className="custom-calendar-separator-half-hour"
          key={s}
          style={{ top: index * intervalsPerHour * rowHeight }}
        >
          <p className="custom-calendar-separator-half-hour-label"></p>
        </div>
      );
    });
  };

  const renderDays = () => {
    return (
      <div className="calendar-days">
        <p
          className={`calendar-days-item ${
            selectedView === "Lunes" ? "calendar-days-item-active" : ""
          }`}
          onClick={() => setSelectedView("Lunes")}
        >
          Lun
        </p>
        <p
          className={`calendar-days-item ${
            selectedView === "Martes" ? "calendar-days-item-active" : ""
          }`}
          onClick={() => setSelectedView("Martes")}
        >
          Mar
        </p>
        <p
          className={`calendar-days-item ${
            selectedView === "Miércoles" ? "calendar-days-item-active" : ""
          }`}
          onClick={() => setSelectedView("Miércoles")}
        >
          Mié
        </p>
        <p
          className={`calendar-days-item ${
            selectedView === "Jueves" ? "calendar-days-item-active" : ""
          }`}
          onClick={() => setSelectedView("Jueves")}
        >
          Jue
        </p>
        <p
          className={`calendar-days-item ${
            selectedView === "Viernes" ? "calendar-days-item-active" : ""
          }`}
          onClick={() => setSelectedView("Viernes")}
        >
          Vie
        </p>
      </div>
    );
  };

  const handleItemDetailClose = () => {
    setSelectedItemIds(null);
    setSelectedItem(null);
  };

  return (
    <div className="calendar-container" ref={containerRef}>
      <button
        className={`calendar-container-button${
          selectedView === "semanal" ? "-active" : ""
        }`}
        onClick={() => setSelectedView("semanal")}
      >
        Semanal
      </button>
      {renderDays()}
      <div
        className="custom-calendar"
        style={{
          display: "grid",
          //height: rowHeight * rowQty,
          gridTemplateColumns: "repeat(6, 1fr)",
          gridTemplateRows: `repeat(${Math.round(rowQty)}, ${rowHeight}px)`,
          gridAutoRows: `${rowHeight}px`
        }}
      >
        {selectedView === "semanal"
          ? renderInscribedSubjects(subjects)
          : renderInscribedSubjectsByDay(subjects, selectedView)}
        {renderSeparators()}
        {renderSeparators2()}
      </div>
      {selectedItemIds && selectedItem ? (
        <>
          <ItemDetail
            item={selectedItem}
            onClose={handleItemDetailClose}
            onDelete={() => setShowModal(true)}
            showActions={editMode.on}
          />
          <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            message={`¿Estás seguro de que deseas eliminar ${selectedItem.title} de tu oferta académica?
          El cupo quedará reservado hasta que confirmes los cambios.`}
            okButton={{
              label: "Continuar",
              buttonFn: () => {
                unsubscribeSection(selectedItemIds?.idCurso);
                handleItemDetailClose();
              },
            }}
            closeButton={{
              label: "Cancelar",
              buttonFn: () => setShowModal(false),
            }}
          />
        </>
      ) : null}
    </div>
  );
};

export default CustomCalendar;
