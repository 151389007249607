import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

interface ModalsOverlays {
  modalFullOverlay: boolean;
}

const initialState: ModalsOverlays = {
  modalFullOverlay: false,
};

export const modalsOverlaysSlice = createSlice({
  name: "modalOverlays",
  initialState,
  reducers: {
    setModalOverlay: (state, action) => {
      state.modalFullOverlay = action.payload;
    },
  },
});

export const selectModalsOverlays = (state: RootState) => state.modalsOverlays;

export const { setModalOverlay } = modalsOverlaysSlice.actions;

export default modalsOverlaysSlice.reducer;
