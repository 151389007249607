import React from "react";
import "./CustomCalendar.css";
import { parseHours } from "../../utils";
import SlDivider from "@shoelace-style/shoelace/dist/react/divider";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

interface IPropsItemDetail {
  item: any;
  onClose: () => void;
  onDelete: () => void;
  showActions: boolean;
}

const ItemDetail = ({ item, onClose, onDelete, showActions }: IPropsItemDetail) => {
  const renderSections = (sections: any[]) => {
    if (sections && sections.length) {
      return sections.map((s, index) => {
        return (
          <div
            className="calendar-item-detail-section"
            key={
              s.id +
              s.subjectData.dia +
              s.subjectData.desde +
              s.subjectData.hasta
            }
          >
            <p className="calendar-item-detail-section-time">
              {s.subjectData.dia}: {parseHours(s.subjectData.desde)} a{" "}
              {parseHours(s.subjectData.hasta)} hs
            </p>
            <p className="calendar-item-detail-section-profesor">
              <span>Profesor</span> {s.subjectData.profesor}
            </p>
            {sections.length > index + 1 ? <SlDivider /> : null}
          </div>
        );
      });
    }
  };

  return (
    <div className="calendar-item-detail">
      <div className="calendar-item-detail-header">
        <h2 className="calendar-item-detail-header-title">
          {item && item.title}
        </h2>
        <SlIcon
          name="x-lg"
          className="calendar-item-detail-header-close"
          onClick={onClose}
        ></SlIcon>
      </div>
      <SlDivider />

      {renderSections(item && item.sections)}
      {showActions ? (
        <div className="calendar-item-detail-actions">
          <button
            className="calendar-item-detail-actions-delete"
            onClick={() => onDelete()}
          >
            Desinscribirse
            <SlIcon name="trash3" onClick={onClose}></SlIcon>
          </button>
          {/* <button className="calendar-item-detail-actions-edit">
          Editar materia
        </button> */}
        </div>
      ) : null}
    </div>
  );
};

export default ItemDetail;
