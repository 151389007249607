import React, { Fragment, useState } from "react";
import "./DesktopSubjectItem.css";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlCheckbox from "@shoelace-style/shoelace/dist/react/checkbox";
import IconText from "../IconText/IconText";
import SubjectDetail from "../SubjectDetail/SubjectDetail";
import SubjectDescription from "../SubjectDescription/SubjectDescription";
import SlDivider from "@shoelace-style/shoelace/dist/react/divider";
import {
  ICalendarSubjects,
  IOfferedSubjectByType,
  IOfferedSubjectData,
  IQuota,
  ISelectedSection,
  ISubjectData,
  ISubjectsByType,
} from "../../types";
import { getQuotaColor, parseHours } from "../../utils";
import { selectEditionMode } from "../../reduxSlices/editorModeSlice";
import { useSelector } from "react-redux";
import { FaUser } from "react-icons/fa6";
import { BsFillPinAngleFill } from "react-icons/bs";
import { BsFillCalendarWeekFill } from "react-icons/bs";
import { getMateriasFijadasAlumno, toggleMateriaFijadaAlumno } from "../../Api/subject";
import { selectStudent } from "../../reduxSlices/studentSlice";
import {
  addCalendarSubject,
  addCalendarSubjectDemoCount,
  removeCalendarSubject,
  selectCalendarSubjectsDemoCount,
  selectCalendarSubjectsList,
  setPinnedSubjectsList,
  subtractCalendarSubjectDemoCount,
} from "../../reduxSlices/subjectsSlice";
import { useAppDispatch } from "../../app/store";
// import 'animate.css';

interface IPropsSubjectItem {
  id: number;
  subject: string;
  subjectId: string;
  section: string;
  selected: boolean;
  subjectData: IOfferedSubjectData;
  quota: IQuota;
  onChange: (data: ISelectedSection) => void;
  disabled: boolean;
  pinned: boolean;
}

const DesktopSubjectItem = ({
  subject,
  section,
  id,
  selected,
  subjectData,
  quota,
  onChange,
  subjectId,
  disabled,
  pinned,
}: IPropsSubjectItem) => {
  const student = useSelector(selectStudent);
  const calendarSubsList = useSelector(selectCalendarSubjectsList);
  const calendarSubsDemoCount = useSelector(selectCalendarSubjectsDemoCount);
  const dispatch = useAppDispatch();
  const [outAnimation, setOutAnimation] = useState("");

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */

  const handlePinSubject = async (idCurso: number, pinned: boolean) => {
    if (pinned) {
      setOutAnimation("animate__slideOutRight");
    }
    const result = await toggleMateriaFijadaAlumno(student.id, idCurso);
    const fijadas = await getMateriasFijadasAlumno(student.id);
    if (fijadas.status === 200 && fijadas.data !== null) {
      dispatch(setPinnedSubjectsList(fijadas.data));
    }else{
      alert("Error al fijar materia");
    }
  };
  /* ----------------------------- ADD to Calendar ---------------------------- */
  const handleToggleCalendarSubject = async (idCurso: number) => {
    const exist = calendarSubsList.some((object: ICalendarSubjects) => object.idCurso === idCurso);
    if (exist) {
      dispatch(removeCalendarSubject(idCurso));
      dispatch(subtractCalendarSubjectDemoCount())
      
      return;
    }
    
    // const colors = ["#2277DA", "#FF4789", "#FF7A30", "#FBC54F", "#97D86C", "#2ED4C6", "#C47DDD"];
    let listado: ICalendarSubjects[] = [];
    
    dispatch(addCalendarSubjectDemoCount())
    // console.log(calendarSubsDemoCount);
    
    
    subjectData.subjectsByType.forEach((dia) => {
      dia.subjectInfo.forEach((horario: any) => {
        
        const newDemo: ICalendarSubjects = {
          colors: {
            background: "rgba(255,255,255,1)",
            border: "black",
          },
          curso: subject,
          desde: horario.values.desde,
          hasta: horario.values.hasta,
          dia: horario.values.dia,
          idCurso: idCurso,
          idMateria: Number(subjectId),
          profesor: horario.values.profesores[0],
          warning: false,
          realInscribed: false,
          superpuesta: 0
        };

        listado.push(newDemo);
      });
    });
    listado.forEach((horario) => dispatch(addCalendarSubject(horario)));
  };

  const handleModalPanelInfo = () => {
    // TODO
    alert("Mostrar panel derecho con detalles de la materia");
  };

  const handlTryReserve = () => {
    onChange({
      tic: subjectData.reserveData.tic,
      tim: subjectData.reserveData.tim,
      idC: subjectData.reserveData.idC,
      idA: subjectData.reserveData.idA,
      idS: subjectData.reserveData.idS,
      section,
      subjectId: subjectId,
    });
  };

  return (
    // <div className={`desktop-subject-item ${selected ? "subject-item-selected" : ""} ${pinned ? "desktop-pinned-subject" : ""} ${pinned ? "animate__slideInDown" : "animate__slideInRight"} animate__animated animate__faster ${outAnimation}`}>
    <div className={`desktop-subject-item ${pinned ? "desktop-pinned-subject" : ""}`}>
      <div className="desktop-item-first-row">
        {quota && quota?.qty < 0 ? (
          <span className="subject-no-available">SIN INFO</span>
        ) : (
          <>
            <FaUser
              title={
                subjectData.reserveData.tic && subjectData.reserveData.tim
                  ? "Cupo: " +
                    String(subjectData.reserveData.tic + " " + subjectData.reserveData.tim) +
                    " | Cuota: " +
                    String(quota.qty || "-")
                  : "sin info"
              }
              style={{ color: quota ? getQuotaColor(quota.availability) : "black" }}
              className="desktop-icon-user-quota"
            />
          </>
        )}
        <p className="desktop-item-title" onClick={handleModalPanelInfo} title={subjectId + " " + id}>
          {section}
        </p>
        <div className="desktop-items-actions">
          <button className="desktop-action btn-pin" title="Fijar materia" onClick={() => handlePinSubject(id, pinned)}>
            <BsFillPinAngleFill />
          </button>
          <button
            className={`desktop-action ${
              calendarSubsList.some((object: ICalendarSubjects) => object.idCurso === id) ? "btn-calendar" : ""
            }`}
            title="Visualizar en calendario"
            onClick={() => handleToggleCalendarSubject(id)}
          >
            <BsFillCalendarWeekFill />
          </button>
          <button className="desktop-action-add" onClick={handlTryReserve}>
            + Inscribirme
          </button>
        </div>
      </div>

      <SlDivider />
      <div className="desktop-item-second-row">
        {/* <div className="desktop-inner-col">
                    <p className="desktop-subject-type">Profesor</p>
                  <p>Apellido, Nombre Profesor</p>
                  </div> */}
        {subjectData.subjectsByType &&
          transformData(subjectData.subjectsByType).map((tipo: any, index: number) => (
            <div className="desktop-inner-col" key={Math.random() + index}>
              <p className="desktop-subject-type">{tipo.titulo}</p>
              {tipo.titulo == "Profesor" &&
                tipo.detalles.map((nombre: any, i: number) => <p key={Math.random() + i}>. {nombre}</p>)}
              {tipo.titulo !== "Profesor" &&
                tipo.titulo !== null &&
                tipo.detalles.map((horario: any, ind: number) => (
                  <p key={Math.random() + ind}>
                    {horario?.dia.slice(0, 2)} {parseHours(horario.desde)} a {parseHours(horario.hasta)} hs
                  </p>
                ))}
            </div>
          ))}
        <div className="desktop-inner-col">
          <p> creditos</p>
        </div>
      </div>
      {/* <hr />
      {JSON.stringify(subjectData.subjectsByType)} */}
    </div>
  );
};

export default DesktopSubjectItem;

function transformData(data: any) {
  const result = [];
  const profesoresSet = new Set();

  data.forEach((section: any) => {
    const { subjectType, subjectInfo } = section;
    const titulo = subjectType;

    const detalles = subjectInfo.map((info: any) => {
      const { dia, desde, hasta, profesores } = info.values;
      profesores.forEach((profesor: any) => profesoresSet.add(profesor));
      return { dia, desde, hasta };
    });
    if (titulo !== null) {
      result.push({ titulo, detalles });
    }
  });

  const profesoresArray = Array.from(profesoresSet);
  result.unshift({ titulo: "Profesor", detalles: profesoresArray });

  return result;
}
