import React from "react";
import "./SubjectDescription.css";

interface IPropsSubjectDescription {
  title: string;
  description: string;
}

const SubjectDescription = ({
  title,
  description,
}: IPropsSubjectDescription) => {
  return description ? (
    <div className="subject-description">
      <h2 className="subject-description-title">{title}</h2>
      <p className="subject-description-info">{description}</p>
      <div className="subject-description-bottom">
        <p className="subject-description-bottom-more">Leer más</p>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SubjectDescription;
