import React from "react";
import SlSpinner from "@shoelace-style/shoelace/dist/react/spinner";
interface Props {
  className?: string;
  style?: React.CSSProperties;
}
function Spinner({ className = "", style = {} }: Props) {
  return (
    <SlSpinner
      style={{
        fontSize: "1.5rem",
        //@ts-ignore
        "--indicator-color": "#00C4B3",
        "--track-color": "#ccebe8",
        ...style,
      }}
      className={className}
    />
  );
}

export default Spinner;
