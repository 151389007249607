import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
interface Props{
  error:boolean
}
const ErrorPage = ({error}:Props) => {
  const navigate = useNavigate();

  if(!error){
    navigate("/")
  }
  /* -------------------------------------------------------------------------- */
  /*                                    MOUNT                                   */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    console.log(
      "%c + Component ERROR >> Mounted ",
      "color: white; background: fuchsia; color: black;"
    );

    return () => {
      console.log(
        "%c - Component ERROR >> Unmounted ",
        "color: white; background: purple; color: black;"
      );
    }
  }, [])

  const handleGoBack = () => {
    navigate('/');
  };


  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Hubo un error.</h1>
      <p>El servidor no se encuentra disponible.</p>
      <button onClick={handleGoBack}>volver</button>
    </div>
  );
};

export default ErrorPage;