export default (key: string): string | undefined => {
    const respuesta = process.env[`REACT_APP_${key}`];
    if (respuesta) {
        return respuesta.toString();
    } else {
        return "";
    }
};

export const conf = (key: string): string => {
    const respuesta = process.env[`REACT_APP_${key}`];
    if (respuesta) {
        return respuesta.toString();
    } else {
        return "";
    }
};
