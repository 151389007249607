import { useState } from "react";
import "./SearchInput.css";
import SlInput from "@shoelace-style/shoelace/dist/react/input";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

interface IPropsSearchInput {
  onChange: (e: string) => void;
  handleClearSearch: () => void;
}

const SearchInput = ({ onChange, handleClearSearch }: IPropsSearchInput) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    setSearchTerm(value);
    onChange(value);
  };

  const handleClearSearchAndInput = () => {
    handleClearSearch();
    setSearchTerm("");
  };

  return (
    <div className="search-container">
      <SlInput
        placeholder=""
        size="medium"
        pill
        className="search-input"
        onSlInput={handleInputChange}
        value={searchTerm}
      >
        <SlIcon style={{ color: "black" }} name="search" slot="prefix"></SlIcon>
      </SlInput>
      {searchTerm && (
        <button className="clear-search" onClick={handleClearSearchAndInput}>
          <SlIcon name="x-lg"></SlIcon>
        </button>
      )}
    </div>
  );
};

export default SearchInput;
