import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectStudent, setStudentInicializado } from "../../reduxSlices/studentSlice";
import { selectInscribedSubjects } from "../../reduxSlices/subjectsSlice";
import CustomCalendar from "../../components/CustomCalendar/CustomCalendar";
import { ICalendarSubjects } from "../../types";
import { assignColorToSubject, checkIfTimeOverlaps } from "../../utils/calendarHelpers";
import EmptyCalendar from "../../components/EmptyCalendar/EmptyCalendar";
import { useDispatch } from "react-redux";

interface Props {}

// TEST arrays for calendar in:
// ----> utils/calendarTestArrays

const Calendar = (props: Props) => {
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const [calendarSubjects, setCalendarSubjects] = useState<ICalendarSubjects[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("%c + Component CALENDAR >> Mounted ", "color: white; background: fuchsia; color: black;");

    return () => {
      console.log("%c - Component CALENDAR >> Unmounted ", "color: white; background: purple; color: black;");
    };
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                            CAMBIO de Inscriptas                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (inscribedSubjects && inscribedSubjects.length) {
      const toCalendarSubjects = () => {
        return inscribedSubjects.reduce((acc, i) => {
          const newSections: any = i.sections.map((s: any) => {
            const subject = {
              idMateria: i.id,
              idCurso: s.idCurso,
              dia: s.subjectData.dia,
              desde: Number(s.subjectData.desde),
              hasta: Number(s.subjectData.hasta),
              profesor: s.subjectData.profesor,
              curso: s.titleMateria,
              colors: assignColorToSubject(Number(i.id)),
              warning: false,
            };

            let overlaps = false;

            acc.forEach((existingSubject: any) => {
              if (
                existingSubject &&
                existingSubject.dia === subject.dia &&
                checkIfTimeOverlaps(existingSubject.desde, existingSubject.hasta, subject.desde, subject.hasta)
              ) {
                existingSubject.warning = true;
                overlaps = true;
              }
            });
            subject.warning = overlaps ? true : false;
            return subject;
          });

          return acc.concat(newSections);
        }, []);
      };
      const calendarSubs = toCalendarSubjects();
      setCalendarSubjects(calendarSubs);
      dispatch(setStudentInicializado(2));
    } else {
      dispatch(setStudentInicializado(0));
    }
  }, [inscribedSubjects]);

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */
  const onSelectedSubject = (id: number) => {
    if (inscribedSubjects) {
      const selectedSubject = inscribedSubjects.find((i) => Number(i.id) === id);
      return selectedSubject;
    }
  };

  return (
    <>
      {calendarSubjects.length ? (
        <CustomCalendar subjects={calendarSubjects} onSelectedItem={onSelectedSubject} />
      ) : (
        <EmptyCalendar />
      )}
    </>
  );
};

export default Calendar;
