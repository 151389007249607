import "./Pages.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OfferList from "../components/OfferList/OfferList";


const SubjectsPage = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    console.log(
      "%c + Component MATERIAS >> Mounted ",
      "color: white; background: fuchsia; color: black;"
    );

    if (!token) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
    }

    return () => {
      console.log(
        "%c - Component MATERIAS >> Unmounted ",
        "color: white; background: purple; color: black;"
      );
    }
  }, [])

  
  return (
    <div className="subjects-main-container">     
        <OfferList/>
    </div>
  );
};

export default SubjectsPage;
