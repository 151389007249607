import { useEffect, useState } from "react";
import "./Header.css";
import Logo from "../../assets/Logo";
import SelectedSubjects from "../SelectedSubjects/SelectedSubjects";
import { useSelector } from "react-redux";
import { selectInscribedSubjects } from "../../reduxSlices/subjectsSlice";
import Clock from "../Clock/Clock";
import { UserMenu } from "../UserMenu/UserMenu";

const Header = () => {
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const [inscribedSubjectsList, setInscribedSubjectsList] = useState<any[]>([]);

  useEffect(() => {
    if (inscribedSubjects && inscribedSubjects.length) {
      const data = inscribedSubjects.map((i: any) => {
        const idCurso = i.sections && i.sections[0].idCurso;
        return {
          title: i.title,
          subjectId: i.id,
          sectionId: idCurso,
        };
      });

      setInscribedSubjectsList(data);
    }
  }, [inscribedSubjects]);

  return (
    <div className="header">
      <div className="header-info">
        <div className="header-info-logo">
          <Logo />
        </div>
        <div className="header-info-data">
          <UserMenu />
        </div>
      </div>
      <Clock />
      <SelectedSubjects data={inscribedSubjectsList} />
    </div>
  );
};

export default Header;
