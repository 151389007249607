import "./EmptyCalendar.css";
import UndrawCalendar from "../../assets/undraw_calendar.svg";

function EmptyCalendar() {
  return (
    <div className="calendar-image-container">
      <img
        className="calendar-image"
        src={UndrawCalendar}
        alt="Calendario vacio"
      />
      <p className="empty-calendar-msg">
        Seleccioná tus materias para empezar a completar y organizar tu
        calendario
      </p>
    </div>
  );
}

export default EmptyCalendar;
