export const websocketTypes = {
  RESPUESTA_RESERVA: "reservaResponse",
  RESPUESTA_DESINSCRIPCION_FALLIDA: "bajaCursoResponse",
  ACTUALIZACION_DE_CUPO: "cupoUpdate",
  RESPUESTA_CONFIRMACION_INSCRIPCION: "confirmacionResponse",
  ACTUALIZACION_OFERTA_KV: "generacionKvOferta",
  ACTUALIZACION_INSCRIPCION_KV: "generacionKvInscripcion",
  LOGOUT_GENERAL: "logoutGeneral",
  LOGOUT_ALUMNO: "logoutAlumno",
};
