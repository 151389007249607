import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setStudentInicializado } from "../../reduxSlices/studentSlice";
import { selectCalendarSubjectsList, selectInscribedSubjects } from "../../reduxSlices/subjectsSlice";
import { ICalendarSubjects } from "../../types";
import { assignColorToSubject, checkIfTimeOverlaps } from "../../utils/calendarHelpers";
import EmptyCalendar from "../EmptyCalendar/EmptyCalendar";
import { useDispatch } from "react-redux";
import DesktopCustomCalendar from "../DesktopCustomCalendar/DesktopCustomCalendar";

interface Props {}

// TEST arrays for calendar in:
// ----> utils/calendarTestArrays

/* --------------------------------- PARSING -------------------------------- */
const addSuperpuestaProperty = (listado: ICalendarSubjects[])=> {
  // Crear un objeto auxiliar para rastrear coincidencias de "dia" y "desde"
  const countMap: { [key: string]: number } = {};

  // Recorrer el listado y actualizar el objeto auxiliar
  listado.forEach(item => {
    const key = `${item.dia}-${item.desde}`;
    if (countMap[key] !== undefined) {
      countMap[key] += 1;
    } else {
      countMap[key] = 0;
    }
    item.superpuesta = countMap[key];
  });

  return listado;
}

const DesktopCalendar = (props: Props) => {
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const calendarSubjectsList = useSelector(selectCalendarSubjectsList);
  const [calendarSubjects, setCalendarSubjects] = useState<ICalendarSubjects[]>([]);
  const dispatch = useDispatch();
  
  // useEffect(() => {
  //   console.log("%c + Component CALENDAR >> Mounted ", "color: white; background: fuchsia; color: black;");

  //   return () => {
  //     console.log("%c - Component CALENDAR >> Unmounted ", "color: white; background: purple; color: black;");
  //   };
  // }, []);

  /* -------------------------------------------------------------------------- */
  /*                            CAMBIO de Inscriptas                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (inscribedSubjects && inscribedSubjects.length) {
      const toCalendarSubjects = () => {
        return inscribedSubjects.reduce((acc, i) => {
          const newSections: any = i.sections.map((s: any) => {
            const subject = {
              idMateria: i.id,
              idCurso: s.idCurso,
              dia: s.subjectData.dia,
              desde: Number(s.subjectData.desde),
              hasta: Number(s.subjectData.hasta),
              profesor: s.subjectData.profesor,
              curso: s.titleMateria,
              colors: assignColorToSubject(Number(i.id)),
              warning: false,
              realInscribed: true,
              superpuesta:0
            };

            let overlaps = false;

            acc.forEach((existingSubject: any) => {
              if (
                existingSubject &&
                existingSubject.dia === subject.dia &&
                checkIfTimeOverlaps(existingSubject.desde, existingSubject.hasta, subject.desde, subject.hasta)
              ) {
                existingSubject.warning = true;
                overlaps = true;
              }
            });
            subject.warning = overlaps ? true : false;
            return subject;
          });

          return acc.concat(newSections);
        }, []);
      };
      const calendarSubs = toCalendarSubjects();
      const calendarSubsInscribedAndStore = [...calendarSubs,...calendarSubjectsList]
      const copy:string = JSON.stringify(calendarSubsInscribedAndStore)
      console.log(addSuperpuestaProperty(JSON.parse(copy)));
      const copyParse = addSuperpuestaProperty(JSON.parse(copy));
      
      
      /* --------------------- Setting materias calendar demo --------------------- */
      setCalendarSubjects(copyParse);

      dispatch(setStudentInicializado(2));
    } else {
      dispatch(setStudentInicializado(0));
    }
  }, [inscribedSubjects,calendarSubjectsList]);

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */
  const onSelectedSubject = (id: number) => {
    if (inscribedSubjects) {
      const selectedSubject = inscribedSubjects.find((i) => Number(i.id) === id);
      return selectedSubject;
    }
  };

  return (
    <>
      {calendarSubjects.length ? (
        <DesktopCustomCalendar subjects={calendarSubjects} onSelectedItem={onSelectedSubject} />
      ) : (
        <EmptyCalendar />
      )}
    </>
  );
};

export default DesktopCalendar;
