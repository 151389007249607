import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reserveSection } from "../../Api/reservation";
import SearchInput from "../SearchInput/SearchInput";
import SubjectList from "../SubjectList/SubjectList";
import Tabs from "../tabs/Tabs";
import { useToastError, useToastInfo } from "../../hooks/useToast";
import { IOfferedSubject, IQuota, IReserveError, ISelectedSection } from "../../types";
import { normalizeText } from "../../utils";
import { selectStudent, setStudentInicializado } from "../../reduxSlices/studentSlice";
import {
  removeSelectedOfferedSubject,
  selectOfferedSubjects,
  selectSelectedOfferedSubjects,
  selectSubjectsQuotas,
  setLoadingRequestInTransit,
} from "../../reduxSlices/subjectsSlice";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";

const filterSubjects = (subjects: IOfferedSubject[], searchTerm: string) => {
  const filteredSubjects = subjects
    .map((s: IOfferedSubject) => {
      const filteredSections = s.sections.filter((section) => {
        const matchesTitle = normalizeText(section.section).includes(normalizeText(searchTerm));
        const matchesProf = section.subjectData.subjectsByType.some((subject) => {
          return subject.subjectInfo.some((info) => {
            return info.values.profesores.some((prof) => {
              return normalizeText(prof).includes(normalizeText(searchTerm));
            });
          });
        });
        return matchesTitle || matchesProf;
      });

      return {
        ...s,
        sections: filteredSections,
      };
    })
    .filter((s: IOfferedSubject) => s.sections.length > 0);

  return filteredSubjects;
};

const OfferList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const student = useSelector(selectStudent);
  const selectedSubjects = useSelector(selectSelectedOfferedSubjects);
  const offeredSubjects = useSelector(selectOfferedSubjects);
  const subjectsQuotas = useSelector(selectSubjectsQuotas);

  const [filteredSubjects, setFilteredSubjects] = useState<IOfferedSubject[]>([]);
  const [filtro, setFiltro] = useState("OBLIGATORIO");
  const [searchTerm, setSearchTerm] = useState("");
  const [subjectsInSearch, setSubjectsInSearch] = useState<IOfferedSubject[]>([]);

  const [reserveError, setReserveError] = useState<IReserveError>({});
  const showError = useToastError();

  const token = localStorage.getItem("token");
  if (!token) {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  }

  useEffect(() => {
    console.log("%c + Component MATERIAS >> Mounted ", "color: white; background: fuchsia; color: black;");

    return () => {
      console.log("%c - Component MATERIAS >> Unmounted ", "color: white; background: purple; color: black;");
    };
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                             SET OFERTA if USER                             */
  /* -------------------------------------------------------------------------- */
  const memoizedFilteredSubjects = useMemo(() => {
    if (offeredSubjects && offeredSubjects.length) {
      dispatch(setStudentInicializado(2));
      return offeredSubjects.filter((s) => s.tipoMateria === filtro);
    } else {
      dispatch(setStudentInicializado(0));
    }
    return [];
  }, [offeredSubjects, filtro]);

  useEffect(() => {
    setFilteredSubjects(memoizedFilteredSubjects);
  }, [memoizedFilteredSubjects]);

  /* -------------------------------- Busqueda -------------------------------- */
  useEffect(() => {
    if (filteredSubjects && searchTerm.trim()) {
      const newSubjects = filterSubjects(filteredSubjects, searchTerm);
      setSubjectsInSearch(newSubjects);
    }
  }, [searchTerm]);

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */
  const handleReserve = async (selectedSection: ISelectedSection) => {
    try {
      if (selectedSection) {
        const results = await reserveSection({
          idC: selectedSection.idC,
          idS: selectedSection.idS,
          idA: selectedSection.idA,
          tim: selectedSection.tim,
          tic: selectedSection.tic,
          idPersona: student.id.toString(),
        });

        console.log(results);

        if (results.data.responseCode !== 200) {
          dispatch(removeSelectedOfferedSubject(selectedSection.idS));
          setReserveError({
            ...reserveError,
            [selectedSection.idC]: results.data.responseMessage,
          });
          showError(results.data.responseMessage, () =>
            setReserveError((prevState) => {
              const newState = prevState;
              delete newState[selectedSection.idC];
              return newState;
            })
          );
        } else {
          await dispatch(setLoadingRequestInTransit(true));
        }
      }
    } catch (error) {
      console.log("error :>> ", error);
    } finally {
    }
  };

  const handleSelectTab = (tipo: string) => {
    if (tipo) {
      setFiltro(tipo);
    } else {
      setFiltro("OBLIGATORIO");
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const renderSubjects = (subjects: IOfferedSubject[]) => {
    return (
      <>
      {subjects.length === 0 && <p>No cuentas con materias disponibles.</p>}
        {subjects.map((s) => {
          return (
            <SubjectList
              key={s.id}
              onReserve={(section: ISelectedSection) => handleReserve(section)}
              selectedSubjects={selectedSubjects}
              error={reserveError}
              subjectId={s.id}
              subjects={s.sections}
              quotas={subjectsQuotas ? subjectsQuotas : {}}
              title={s.title}
            />
          );
        })}
      </>
    );
  };

  return (
    <>
      {filteredSubjects.length === 0 && student.id && offeredSubjects === null ? (
        <Loader text="Cargando" />
      ) : (
        <>
          <SearchInput onChange={(e: string) => setSearchTerm(e)} handleClearSearch={handleClearSearch} />
          <Tabs filtro={filtro} handleSelectTab={handleSelectTab} />
          {searchTerm ? renderSubjects(subjectsInSearch) : renderSubjects(filteredSubjects)}
        </>
      )}
    </>
  );
};

export default OfferList;
