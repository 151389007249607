import React from "react";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlDialog from "@shoelace-style/shoelace/dist/react/dialog";
import "./Modal.css";

interface IModalButton {
  label: string;
  buttonFn: () => void;
}

interface IPropsModal {
  open: boolean;
  onClose: () => void;
  message: string;
  okButton?: IModalButton;
  closeButton?: IModalButton;
}

const Modal = ({
  open,
  onClose,
  message,
  okButton,
  closeButton,
}: IPropsModal) => {
  return (
    <SlDialog
      open={open}
      onSlAfterHide={onClose}
      className="shoelace-modal"
      noHeader
    >
      <div className="custom-modal">
        <p className="custom-modal-message">{message}</p>
        <div className="custom-modal-actions">
          {closeButton && (
            <SlButton
              slot="footer"
              className="custom-modal-actions-button-cancel"
              onClick={closeButton.buttonFn}
            >
              {closeButton.label}
            </SlButton>
          )}
          {okButton && (
            <SlButton
              slot="footer"
              className="custom-modal-actions-button-ok"
              variant="default"
              onClick={okButton.buttonFn}
            >
              {okButton.label}
            </SlButton>
          )}
        </div>
      </div>
    </SlDialog>
  );
};

export default Modal;
