import React from "react";
import "./Navbar.css";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { Link, useLocation } from "react-router-dom";
import { setEditorModeState } from "../../Api/editorMode";
import { selectStudent } from "../../reduxSlices/studentSlice";
import {
  selectEditionMode,
  updateEditionModeData,
} from "../../reduxSlices/editorModeSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectInscribedSubjects } from "../../reduxSlices/subjectsSlice";

const Navbar = () => {
  const dispatch = useDispatch();
  const student = useSelector(selectStudent);
  const inscripciones = useSelector(selectInscribedSubjects);
  const { on } = useSelector(selectEditionMode);
  const location = useLocation();

  const turnOnEditorMode = () => {
    if (!on) {
      setEditorModeState(student.id, 1).then((res) => {
        dispatch(updateEditionModeData({ on: true }));        
      });
    }
  };

  return (
    <div className="navbar">
      <Link
        to="/"
        className={`navbar-item ${
          location.pathname === "/" ? "active-path" : ""
        }`}
      >
        <SlIcon name="calendar-week" className="navbar-item-icon"></SlIcon>
        <p className="navbar-item-label">Calendario</p>
      </Link>
      <Link
        to="/materias"
        className={`navbar-item ${
          location.pathname === "/materias" ? "active-path" : ""
        }`}
      >
        <SlIcon name="journals" className="navbar-item-icon"></SlIcon>
        <p className="navbar-item-label">Materias</p>
      </Link>
      <button
        className={`navbar-item editor-mode-btn ${
          on ? "editor-mode-btn-on" : ""
        }`}
        onClick={turnOnEditorMode}
      >
        <SlIcon name="pencil-square" className="navbar-item-icon"></SlIcon>
        <p className="navbar-item-label">Editar</p>
      </button>
    </div>
  );
};

export default Navbar;
