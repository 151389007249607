import "@shoelace-style/shoelace/dist/themes/light.css";
import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate, Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getEditorModeState } from "./Api/editorMode";
import { appState } from "./Api/parameters";
import "./App.css";
import EditorActions from "./components/EditorActions/EditorActions";
import MobileLayout from "./components/MobileLayout/Layout";
import DesktopLayout from "./components/DesktopLayout/Layout";
import { AppState, AppStateType } from "./enums/appState.enum";
import { useToastError, useToastInfo } from "./hooks/useToast";
import LoginPage from "./pages/Login/LoginPage";
import { updateEditionModeData } from "./reduxSlices/editorModeSlice";
import { updateParametersData } from "./reduxSlices/parametersSlice";
import { selectStudent, setStudentInicializado, updateStudentData } from "./reduxSlices/studentSlice";
import {
  selectInscribedSubjects,
  setInscribedSubjects,
  setLoadingRequestInTransit,
  setOfferedSubjects,
  setPinnedSubjectsList,
  setSubjectsQuota,
} from "./reduxSlices/subjectsSlice";

//@ts-ignore
import { withOneTabEnforcer } from "react-one-tab-enforcer";
import Warning from "./components/Warning/Warning";
import SubjectDetailsModal from "./components/SubjectDetailsModal/SubjectDetailsModal";
import { selectDetailSubject, updateDetailSubject } from "./reduxSlices/detailSubjectSlice";
import { auth, getEstadoAlumno } from "./Api/authentication";
import { getMateriasAlumno, getMateriasFijadasAlumno, getMateriasInscriptasAlumno } from "./Api/subject";
import { IInscribedSubject, IOfferedSubject, IQuota } from "./types";
import { getCuposAlumno, getCuposCurso } from "./Api/reservation";
import { EstadoAlumnoCarrera } from "./enums/alumnoCarrera.enum";
import WebsocketManager from "./components/WebsocketManager/WebsocketManager";
import SubjectsPage from "./pages/SubjectsPage";
import HomePage from "./pages/HomePage";
import ErrorPage from "./pages/ErrorPage";
import { useAppDispatch } from "./app/store";
import { getModoCarritoParam } from "./reduxAsyncThunks/parameters";
import useMediaQueries from "./hooks/useMediaQuery";
import { updateCupoCurso } from "./reduxAsyncThunks/subjects";
import { StorageKeys } from "./utils/localStorage";
import DesktopMain from "./components/DesktopMain/DesktopMain";

function App() {
  setBasePath("https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.15.0/cdn/");
  const student = useSelector(selectStudent);
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const showError = useToastError();
  const showInfo = useToastInfo();
  const dispatch = useAppDispatch();
  const [tokenState, setTokenState] = useState("");
  const [serverError, setServerError] = useState(false);
  const detailSubject = useSelector(selectDetailSubject);

  const getFlagParameters = async () => {
    await dispatch(getModoCarritoParam());
  };

  useEffect(() => {
    getFlagParameters();
  }, []);

  const getUserId = async () => {
    const alumno = await auth();

    if (alumno.error) {
      console.log("cerrar sesion");
      localStorage.clear();
    }

    dispatch(
      updateStudentData({
        id: alumno.id,
        email: alumno.email,
        completeName: {
          name: alumno.nombre,
          lastName: alumno.apellido,
        },
      })
    );
  };

  const checkUserInitAndGetStudentData = async () => {
    let result;
    try {
      const res = await getEstadoAlumno(""); // TODO: revisar que no es necesario el mail
      if (res && res.error && res.error.response.status != 200) {        
        localStorage.clear();
        dispatch(setStudentInicializado(0));
        return false;
      }
      if (res) {
        const { estado } = res;
        console.log("Unauthorized" == res);
        console.log("Estado alumno :>>", res);
        if (estado === EstadoAlumnoCarrera.KV_INICIALIZADO) {
          await getUserId();
          await dispatch(setStudentInicializado(2));
          result = true;
        } else if (estado === EstadoAlumnoCarrera.INICIALIZACION_KV_PENDIENTE) {
          result = false;
          await dispatch(setStudentInicializado(1));
        }
      } else {
        localStorage.clear();
        result = false;
        await dispatch(setStudentInicializado(0));
      }
    } catch (err) {
      result = false;
      await dispatch(setStudentInicializado(2));
      console.error("ERROR :>>", err);
      localStorage.clear();
    }

    return result;
  };

  useEffect(() => {
    console.log("%c + Component APP >> Mounted ", "color: white; background: fuchsia; color: black;");

    return () => {
      console.log("%c - Component APP >> Unmounted ", "color: white; background: purple; color: black;");
    };
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                  APP Mount                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    const tkn = localStorage.getItem("token") || "";
    setTokenState(tkn);

    /* ----------------------------- set parameters ----------------------------- */
    appState()
      .then((res) => {
        if (Object.values(AppState).includes(res)) {
          dispatch(
            updateParametersData({
              appState: res as AppStateType,
              started: true,
            })
          );
        } else {
          console.error("Estado no válido");
          setServerError(true);
          // localStorage.clear();
          // window.location.reload()
        }
      })
      .catch((err) => {
        console.log("ERROR :>>", err);
      });

    /* ------------------ Chequeamos si esta el KV en progreso ------------------ */
    // getEstadoAlumno("").then((res) => {
    //   // dispatch(setStudentInicializado(res.estado));
    // });
  }, []);

  useEffect(() => {
    /* ------------------------------- set session ------------------------------ */
    if (tokenState) {
      checkUserInitAndGetStudentData();
    }

    let intervalId: any;
    if (student.studentInitState !== 2 && tokenState) {
      intervalId = setInterval(async () => {
        console.log("INTERVALOO COMENZADO");
        const initOk = await checkUserInitAndGetStudentData();        
        if (initOk) {
          clearInterval(intervalId);
          console.log("INTERVALOO Finalizado");
        }
      }, 5000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [tokenState]);

  /* -------------------------------------------------------------------------- */
  /*                           If USER: SET STUDENT STORE DATA                  */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (student.id && !student.bloqueo.activo) {
      console.log(student);
      
      /* ---------------------------- STORE EDITOR MODE --------------------------- */
      getEditorModeState(student.id).then((res) => {
        console.log("%cModo editor :>>", "color: yellowGreen", Number(res) === 1 ? "ON" : "OFF");
        if (Number(res) === 1) {
          dispatch(updateEditionModeData({ on: true }));
        } else {
          dispatch(updateEditionModeData({ on: false }));
        }
      });
      /* ----------------------------- STORE SUBJECTS ----------------------------- */
      // Inscribed and Offerred
      const getStoredSubjects = async () => {
        console.log("ACTUALIZANDO INSCRIPCIONES X APP");
        /* ----------------------------- Set Inscriptas ----------------------------- */
        const inscriptas = await getMateriasInscriptasAlumno(student.id);

        if (inscriptas.status === 200) {
          dispatch(setInscribedSubjects(inscriptas.data));
        }
        /* ------------------------------- Set Oferta ------------------------------- */
        const oferta = await getMateriasAlumno(student.id.toString());

        if (oferta.status === 200 && inscriptas?.data?.length) {
          const registeredSubjectIds = inscriptas.data.map((i: IInscribedSubject) => i.id);
          if (oferta.data.length) {
            // filtramos de la oferta las que ya estan inscriptas
            const finalOfferedSubjects = oferta.data.filter(
              (c: IOfferedSubject) => !registeredSubjectIds.includes(c.id)
            );
            dispatch(setOfferedSubjects(finalOfferedSubjects));
          }else{
            dispatch(setOfferedSubjects(oferta.data));
          }
        } else {
          dispatch(setOfferedSubjects(oferta.data));
        }
        /* ------------------------------- Set Fijadas ------------------------------ */
        const fijadas = await getMateriasFijadasAlumno(student.id)
        if(fijadas.status === 200 && fijadas.data !== null){
          dispatch(setPinnedSubjectsList(fijadas.data))
        }
        
      };
      getStoredSubjects();
      /* ------------------------------- Set CUOTAS ------------------------------- */
      //  Set quotas state
      const getSubjectQuotas = async () => {
        const results = await getCuposAlumno(student.id.toString());
        console.log("getCuposAlumno :>> ", results);
        if (results && results.status === 200) {
          // setSubjectsQuotas(results.data);
          dispatch(setSubjectsQuota(results.data));
          if (results.response) {
            const synced = localStorage.getItem(StorageKeys.CupoCursosSynced);
            console.log("CupoCursosSynced :>> ", synced);
            if (synced !== "true") {
              let promises: any[] = [];
              for (const alumnoCupo of results.response) {
                if (alumnoCupo.cantidad !== -1) {
                  promises.push(dispatch(updateCupoCurso(alumnoCupo.idCupo)));
                }
              }
              await Promise.all(promises);
              localStorage.setItem(StorageKeys.CupoCursosSynced, "true");
            }
          }
        }
      };
      getSubjectQuotas();
    }
  }, [student.id]);

  /* -------------------------------------------------------------------------- */
  /*                             SNAPSHOT Inscribed                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    dispatch(setLoadingRequestInTransit(false));
    if (inscribedSubjects === null) {
      localStorage.removeItem("initialSnapshot");
    }
    const snapshot = localStorage.getItem("initialSnapshot");
    if (!snapshot && inscribedSubjects) {
      localStorage.setItem("initialSnapshot", JSON.stringify(inscribedSubjects));
    }
  }, [inscribedSubjects]);

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */

  const handleCloseDetailSubjectModal = () => {
    dispatch(updateDetailSubject({ sections: [] }));
  };
  const handleToken = (tkn: string) => {
    setTokenState(tkn);
  };

  const { md, lg } = useMediaQueries();

  /* -------------------------------------------------------------------------- */
  /*                          DESKTOP Layout & Routing                          */
  /* -------------------------------------------------------------------------- */
  if (lg) {
    return (
    <BrowserRouter>
      <DesktopLayout error={serverError}>
        <WebsocketManager />
        <Routes>
          <Route element={<LoginPage handleToken={handleToken} />} path="/login" />
          <Route element={<DesktopMain />} path="/" />
          <Route path="/error" element={<ErrorPage error={serverError} />} />
          <Route path="*" element={<Navigate to="/" />} /> 
        </Routes>
        <ToastContainer />
      </DesktopLayout>
    </BrowserRouter>
    )
  }

  /* -------------------------------------------------------------------------- */
  /*                           MOBILE Layout & Routing                          */
  /* -------------------------------------------------------------------------- */
  return (
    <BrowserRouter>
      <MobileLayout error={serverError}>
        {/* <ModeHeader /> */}
        <EditorActions />
        {detailSubject.sections.length > 0 && (
          <SubjectDetailsModal close={handleCloseDetailSubjectModal} sections={detailSubject.sections} />
        )}
        <WebsocketManager />
        <Routes>
          <Route element={<LoginPage handleToken={handleToken} />} path="/login" />
          {/* <Route element={<ProtectedRoute isAuthenticated={student.id} />}> */}
          <Route element={<HomePage />} path="/" />
          <Route element={<SubjectsPage />} path="/materias" />
          <Route path="/error" element={<ErrorPage error={serverError} />} />
          <Route path="*" element={<Navigate to="/" />} /> 
          {/* </Route> */}
        </Routes>
        <ToastContainer />
      </MobileLayout>
    </BrowserRouter>
  );
}

export default withOneTabEnforcer({
  appName: "di-tella-app",
  OnlyOneTabComponent: Warning,
})(App);

interface ProtectedRouteProp {
  isAuthenticated: number;
}
const ProtectedRoute = ({ isAuthenticated }: ProtectedRouteProp) => {
  return isAuthenticated !== 0 ? <Outlet /> : <Navigate to="/login" />;
};
