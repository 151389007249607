import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { ParametersState } from "../reduxSlices/parametersSlice";
import { getCuposCurso } from "../Api/reservation";
import { ISubjectsSliceState } from "../reduxSlices/subjectsSlice";

export const updateCupoCurso = createAsyncThunk("front/updateCupoCurso", async (cupoId: number, thunkAPI) => {
  try {
    console.log("updateCupoCurso...");
    const response = await getCuposCurso(cupoId);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const updateCupoCursoBuilder = (builder: ActionReducerMapBuilder<ISubjectsSliceState>) => {
  builder.addCase(updateCupoCurso.fulfilled, (state, action) => {
    console.log("updateCupoCurso fulfilled :>> ", action);
    const newQuotas = { ...state.subjectsQuotas, ...action.payload };
    state.subjectsQuotas = newQuotas;
  });
  builder.addCase(updateCupoCurso.rejected, (state, action) => {
    console.log("updateCupoCurso rejected :>> ", action);
  });
};
