import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

interface IStudentCompleteName {
  name: string;
  lastName: string;
}

interface IStudentState {
  id: number;
  email: string;
  completeName: IStudentCompleteName;
}

export const studentSlice = createSlice({
  name: "student",
  initialState: {
    id: 0,
    email: "",
    completeName: { name: "", lastName: "" },
    studentInitState: 0,
    bloqueo: { mensaje: "", activo: false },
  },
  reducers: {
    updateStudentData: (state, action) => {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.completeName = action.payload.completeName;
    },
    setStudentInicializado: (state, action) => {
      state.studentInitState = action.payload;
    },
    setStudentBloqueo: (state, action) => {
      state.bloqueo = action.payload;
    },
  },
});

export const selectStudent = (state: RootState) => state.student;

export const { updateStudentData, setStudentInicializado, setStudentBloqueo } = studentSlice.actions;

export default studentSlice.reducer;
