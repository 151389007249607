import { useEffect, useState } from "react";
import "./Clock.css";
import { selectEditionMode } from "../../reduxSlices/editorModeSlice";
import { useSelector } from "react-redux";
import { getRemainingTimeEdition } from "../../Api/editorMode";
import { selectStudent } from "../../reduxSlices/studentSlice";
import { selectCartModeFlag } from "../../reduxSlices/parametersSlice";

export default function Clock() {
  const { on } = useSelector(selectEditionMode);
  const { id } = useSelector(selectStudent);
  const [time, setTime] = useState(0);
  const modoCarrito  = useSelector(selectCartModeFlag);
  
  useEffect(() => {
    if (on) {
      const getSeconds = async () => {
        const data = await getRemainingTimeEdition(id);
        setTime(data.time);
      };
      getSeconds();
    } else {
      setTime(0);
    }
  }, [on]);

  useEffect(() => {
    if (time <= 0) return;

    const intervalId = setInterval(() => {
      setTime((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [time]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return  <div className="clock-box">{on && modoCarrito && <p className="clock-mins">{formatTime(time)}</p>}</div>;
}
