import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { AppState, AppStateType } from "../enums/appState.enum";
import { getModoCarritoBuilder } from "../reduxAsyncThunks/parameters";

export interface ParametersState {
  appState: AppStateType;
  started: boolean;
  cartMode: { carrito: boolean; confirmaciones: boolean } | null;
}

interface UpdateParametersPayload {
  appState: AppStateType;
  started: boolean;
}

const initialState: ParametersState = {
  appState: AppState.ABIERTO,
  started: false,
  cartMode: null,
};

export const parametersSlice = createSlice({
  name: "parameters",
  initialState,
  reducers: {
    updateParametersData: (state, action: PayloadAction<UpdateParametersPayload>) => {
      state.appState = action.payload.appState;
      state.started = action.payload.started;
    },
  },
  extraReducers: (builder) => {
    getModoCarritoBuilder(builder);
  },
});

export const selectParameter = (state: RootState) => state.parameters;
export const selectCartModeFlag = (state: RootState) => state.parameters.cartMode?.carrito;
export const selectConfirmationsFlag = (state: RootState) => state.parameters.cartMode?.confirmaciones;

export const { updateParametersData } = parametersSlice.actions;

export default parametersSlice.reducer;
