import { toast } from 'react-toastify';
import { stripHtml } from "string-strip-html";

export const useToastError = () => {
  const showError = (message: string, onClose?: () => void) => {
    const mHtml = stripHtml(message || "").result;
    toast.error(mHtml, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      onClose: onClose
    });
  };

  return showError;
};

export const useToastInfo = () => {
  const showInfo = (message: string, onClose?: () => void) => {
    const mHtml = stripHtml(message || "").result;
    toast.info(mHtml, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      onClose: onClose
    });
  };

  return showInfo;
};