const redirectUri = process.env.REACT_APP_REDIRECT_URI;
console.log(redirectUri);


export const msalConfig = {
  auth: {
    clientId: "33144be2-92c3-46b3-9b0b-4ed89e358e60",
    authority: "https://login.microsoftonline.com/20545e33-c094-43ee-aba8-b0c0240f5219",
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
    graphMeEndpoint: "Enter_the_Graph_Endpoint_Herev1.0/me" //e.g. https://graph.microsoft.com/v1.0/me
};
