import React from "react";
import "./IconText.css";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

interface IPropsIconText {
  color?: string;
  text: string;
  textSize?: number;
  icon: string;
  iconWidth?: number;
  iconHeight?: number;
}

const IconText = ({
  color = "#424242",
  text,
  icon,
  textSize = 16,
  iconWidth = 16,
  iconHeight = 16,
}: IPropsIconText) => {
  return (
    <div className="icon-text" style={{color: color}}>
      <div style={{ width: iconWidth, height: iconHeight }} className="icon-text-svg">
        <SlIcon
          name={icon}
          style={{ color: color, height: '100%' }}
        ></SlIcon>
      </div>
      <p style={{ fontSize: textSize }}>{text}</p>
    </div>
  );
};

export default IconText;
