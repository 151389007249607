import SlButton from "@shoelace-style/shoelace/dist/react/button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirmChanges, getEditorModeState, setEditorModeState } from "../../Api/editorMode";
import { EstadoEdicionInscripciones } from "../../enums/editonMode.enum";
import { selectEditionMode, updateEditionModeData } from "../../reduxSlices/editorModeSlice";
import { selectStudent } from "../../reduxSlices/studentSlice";
import Modal from "../Modal/Modal";
import TransitionModal from "../TransitionModal/TransitionModal";
import "./EditorActions.css";
import {
  clearSelectedOfferedSubject,
  selectInscribedSubjects,
  setLoadingRequestInTransit,
} from "../../reduxSlices/subjectsSlice";
import { selectCartModeFlag } from "../../reduxSlices/parametersSlice";

const EditorActions = () => {
  const dispatch = useDispatch();
  const student = useSelector(selectStudent);
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const { on } = useSelector(selectEditionMode);
  const [confirmar, setConfirmar] = useState(false);
  const [cancelar, setCancelar] = useState(false);
  const modoCarrito = useSelector(selectCartModeFlag);
  console.log("modoCarrito :>> ", modoCarrito);

  const [cancelModal, setCancelModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const [openTransitionModal, setOpenTransitionModal] = useState("");

  const onEditionModeSuccesfullyClosed = () => {
    dispatch(updateEditionModeData({ on: false }));
    const actualSnapshot = JSON.stringify(inscribedSubjects);
    localStorage.setItem("initialSnapshot", actualSnapshot);
    setConfirmar(false);
    setConfirmModal(false);
    setCancelar(false);
    setCancelModal(false);
    setOpenTransitionModal("");
  };

  useEffect(() => {
    if (openTransitionModal) {
      const pollEditorMode = async () => {
        const pollInterval = 5000;
        try {
          const results = await getEditorModeState(student.id);
          const nuevoEstado = Number(results);
          if (nuevoEstado == EstadoEdicionInscripciones.CONFIRMANDO) {
            setTimeout(pollEditorMode, pollInterval);
          } else {
            if (nuevoEstado == EstadoEdicionInscripciones.ACTIVADA) {
              dispatch(updateEditionModeData({ on: true }));
              setConfirmModal(false);
              setOpenTransitionModal("");
            } else {
              onEditionModeSuccesfullyClosed();
            }
          }
        } catch (error) {
          console.log(error);
        }
      };

      pollEditorMode();
    }
  }, [openTransitionModal]);

  const confirmarCambios = async () => {
    console.log("Posible confirmacion...");
    setConfirmar(true);
    setConfirmModal(true);
  };

  const cancelEditorMode = async () => {
    console.log("Posible cancelacion...");

    const initialSnapshot = localStorage.getItem("initialSnapshot");
    const actualSnapshot = JSON.stringify(inscribedSubjects);

    dispatch(setLoadingRequestInTransit(false));
    // si son iguales cancelar directo
    if (actualSnapshot === initialSnapshot) {
      setCancelar(true); //loading
      localStorage.setItem("initialSnapshot", actualSnapshot);
      await setEditorModeState(student.id, 0);
      dispatch(updateEditionModeData({ on: false }));
      setCancelar(false); //loading
      return;
    } else {
      setCancelModal(true);
      setCancelar(true); //loading
    }
  };

  const handleSiCancelar = async () => {
    console.log("Aguardando regeneracion de KV...");
    setOpenTransitionModal(modoCarrito ? "Deshaciendo cambios..." : "Cerrando modo edición...");
    dispatch(clearSelectedOfferedSubject());
    await setEditorModeState(student.id, 0);
  };

  const handleNoCancelar = () => {
    setCancelar(false);
    setCancelModal(false);
  };

  const handleSiConfirmar = async () => {
    console.log("Aguardando confirmacion del proceso...");
    setOpenTransitionModal("Confirmando cambios...");
    const confirm = await confirmChanges();
    const changeEditor = await setEditorModeState(student.id, 3);
    console.log("CONFIRM CHANGES -->", confirm);
    console.log("CHANGE EDITOR -->", changeEditor);
  };

  const handleNoConfirmar = () => {
    setConfirmar(false);
    setConfirmModal(false);
  };

  if (!on) {
    return null;
  }

  return (
    <div className="editor-actions">
      {modoCarrito && (
        <SlButton
          className="confirmar"
          size="small"
          variant="default"
          onClick={confirmarCambios}
          loading={confirmar}
          disabled={confirmar || cancelar}
        >
          Confirmar Cambios
        </SlButton>
      )}

      {modoCarrito && (
        <SlButton
          className="cancelar"
          size="small"
          variant="neutral"
          outline
          onClick={cancelEditorMode}
          loading={cancelar}
          disabled={cancelar || confirmar}
        >
          Cancelar
        </SlButton>
      )}

      {!modoCarrito && (
        <SlButton
          className="cancelar"
          size="small"
          variant="neutral"
          outline
          onClick={cancelEditorMode}
          loading={cancelar}
          disabled={cancelar || confirmar}
        >
          Cerrar modo edición
        </SlButton>
      )}

      <Modal
        open={cancelModal}
        onClose={handleNoCancelar}
        message={modoCarrito ? "¿Desea cancelar las modificaciones?" : "¿Desea cerrar el modo edición?"}
        okButton={{
          label: "Si",
          buttonFn: handleSiCancelar,
        }}
        closeButton={{
          label: "No",
          buttonFn: handleNoCancelar,
        }}
      />
      <Modal
        open={confirmModal}
        onClose={handleNoConfirmar}
        message="¿Desea confirmar las modificaciones?"
        okButton={{
          label: "Si",
          buttonFn: handleSiConfirmar,
        }}
        closeButton={{
          label: "No",
          buttonFn: handleNoConfirmar,
        }}
      />
      <TransitionModal open={openTransitionModal ? true : false} text={openTransitionModal} />
    </div>
  );
};

export default EditorActions;
