import React, { useEffect, useState } from "react";
import "./SubjectList.css";
import SubjectItem from "../SubjectItem/SubjectItem";
import {
  IOfferedSubject,
  IOfferedSubjectSection,
  IQuota,
  IReserveError,
  ISelectedSection,
  ISubjectSection,
} from "../../types";
import Modal from "../Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { addSelectedOfferedSubject, removeSelectedOfferedSubject } from "../../reduxSlices/subjectsSlice";
import { useToastInfo } from "../../hooks/useToast";
import { selectConfirmationsFlag } from "../../reduxSlices/parametersSlice";

interface IPropsSubjectList {
  subjects: IOfferedSubjectSection[];
  quotas: Record<string, IQuota>;
  title: string;
  subjectId: string;
  selectedSubjects: any[];
  error: IReserveError;
  onReserve: (selectedSection: ISelectedSection) => Promise<void>;
}

const SubjectList = ({ subjects, title, quotas, subjectId, selectedSubjects, error, onReserve }: IPropsSubjectList) => {
  const showInfo = useToastInfo();
  const [selectedSection, setSelectedSection] = useState<ISelectedSection>();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const confirmationsEnabled = useSelector(selectConfirmationsFlag);
  
  const handleChange = (data: ISelectedSection) => {
    if (confirmationsEnabled) {
      if (selectedSection?.idC === data.idC) {
        setSelectedSection(undefined);
      } else {
        setOpen(true);
        setSelectedSection(data);
        dispatch(
          addSelectedOfferedSubject({
            idS: data.idS,
            subjectId: data.subjectId,
          })
        );
      }
    } else {
      handleReserve(data);
      dispatch(addSelectedOfferedSubject({ idS: data.idS, subjectId: data.subjectId }));
    }
  };

  useEffect(() => {
    if (error && selectedSection) {
      const keys = Object.keys(error);
      if (keys.length && keys.includes(selectedSection?.idC.toString())) {
        setSelectedSection(undefined);
      }
    }
  }, [error]);

  const renderSubjectItem = (subjects: IOfferedSubjectSection[]) => {
    const flattenSubjects = subjects.flat();
    return flattenSubjects.map((s: IOfferedSubjectSection) => {
      return (
        <SubjectItem
          id={s.id}
          key={s.id}
          subjectId={subjectId}
          disabled={selectedSubjects.some((selected) => selected.subjectId === subjectId)}
          section={s.section}
          subjectData={s.subjectData}
          selected={selectedSubjects.some((selected) => selected.idS === s.id)}
          subject={title}
          quota={quotas[s.id]}
          onChange={handleChange}
        />
      );
    });
  };

  const handleReserve = async (section?: ISelectedSection) => {
    if (selectedSection) {
      await onReserve(selectedSection);
      setOpen(false);
    } else if (section) {
      await onReserve(section);
    }
    showInfo("Procesando inscripción...");
  };

  const handleRefuse = (idS: number) => {
    setOpen(false);
    setSelectedSection(undefined);
    dispatch(removeSelectedOfferedSubject(idS));
  };

  return (
    <div className="subject-list">
      {renderSubjectItem(subjects)}
      <>
        {selectedSection && (
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            message={`¿Desea inscribirse en el curso ${selectedSection.section}?`}
            okButton={{
              label: "Si",
              buttonFn: handleReserve,
            }}
            closeButton={{
              label: "No",
              buttonFn: () => handleRefuse(selectedSection.idS),
            }}
          />
        )}
      </>
    </div>
  );
};

export default SubjectList;
