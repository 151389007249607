import axios from "axios";

export const getMateriasAlumno = async (idPersona: string) => {
  const { data, status } = await axios.post(`/api/materias?r=${Math.random().toString()}`, { idPersona });
  return { data, status };
};

export const getMateriasInscriptasAlumno = async (idPersona: number) => {
  const { data, status } = await axios.post(`/api/materiasInscriptas?r=${Math.random().toString()}`, {
    id: idPersona,
  });
  console.log("INSCRIPCIONES: " + JSON.stringify(data?.length))
  return { data, status };
};

export const getMateriasFijadasAlumno = async (idPersona: number) => {
  const { data, status } = await axios.post(`/api/materiasFijadas?r=${Math.random().toString()}`, {
    id: idPersona,
  });
  console.log("FIJADAS: " + JSON.stringify(data?.length))
  return { data, status };
};
export const toggleMateriaFijadaAlumno = async (idPersona: number, cursoId:number) => {
  const { data, status } = await axios.post(`/api/toggleMateriasFijadas?r=${Math.random().toString()}`, {
    id: idPersona,
    idCurso: cursoId
  });
  console.log("MATERIA FIJADA: " + data)
  return { data, status };
};
