import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Calendar from "../components/Calendar/Calendar";

const HomePage = () => {
  const navigate = useNavigate();
  
  const token = localStorage.getItem("token");
  
  useEffect(() => {
    console.log(
      "%c + Component HOME >> Mounted ",
      "color: white; background: fuchsia; color: black;"
    );

    if (!token) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
    }

    return () => {
      console.log(
        "%c - Component HOME >> Unmounted ",
        "color: white; background: purple; color: black;"
      );
    }
  }, [])

  return (
    <div style={{ padding: 12, height: "100%" }}>
      <Calendar/>
    </div>
  );
};

export default HomePage;
