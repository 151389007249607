import { useEffect, useMemo, useState } from "react";

function useMediaQuery(query: string) {
  const mediaQuery = useMemo(() => window.matchMedia(query), [query]);
  const [match, setMatch] = useState(mediaQuery.matches);

  useEffect(() => {
    const onChange = () => {
        setMatch(mediaQuery.matches);
    console.log(mediaQuery);
    };
    mediaQuery.addEventListener("change", onChange);
    

    return () => mediaQuery.removeEventListener("change", onChange);
  }, [mediaQuery]);

  return match;
}

export default function useMediaQueries() {
    const md = useMediaQuery("(min-width: 700px)");
    const lg = useMediaQuery("(min-width: 1000px)");
  
    return { md, lg };
  }
  