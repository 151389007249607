import axios from "axios";

export const checkRole = async (email: string) => {
  try {
    const { data, status } = await axios.post("/userRole", { email: email });
    return data;
  } catch (error) {
    return { error };
  }
};
